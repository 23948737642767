import * as colors from 'constants/colors'
import styled from "styled-components"

export const Container = styled.div`

`

export const SearchContainer = styled.section`
  display: grid;
  align-items: end;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  gap: 5px;
  margin: 20px 0 10px;

  input{
    height: 40px;
  }

  .filter-input,
  .filter-button{
    width: 100%;
  }
  
  
  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr 1fr auto auto auto;

    .filter-button{
      width: auto;
    }
  }
`

export const Note = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
  background-color: rgb(225, 237, 255);
  color: rgb(96, 131, 183);
  border-radius: 8px;
  padding: 10px;
  margin: 15px 0;
`

export const TableContainer = styled.section`
  overflow: auto;
  min-width: 200px;
  margin: 0;
`

export const Td = styled.td`
  white-space: nowrap;
  text-align: center;
`

export const Table = styled.table`
  width: 100%;
  border: 1px solid #bfbfbf;
  border-collapse: collapse;
  border-radius: 10px;
  td, th{
    padding: 10px;
    border: 1px solid #bfbfbf;
    border-collapse: collapse;
    vertical-align: middle;
  }
`

export const ButtonPdf = styled.section`
  display: grid;
  place-items: center;
  padding: 5px 10px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${colors.primary01};
  fill: ${colors.neutral02};
  &:hover{
    background-color: #ed7e4f;
  }
`

export const ContainerModal = styled.div`
  display: grid;
  gap: 15px;
  margin: 15px 0;
`


export const ButtonExcel = styled.section<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border-radius: 5px;
  height: 40px;
  padding: 5px 10px;
  color: #fff;
  font-weight: 600;
  background: ${({ disabled }) => disabled ? '#cfcfcf' : '#1c8320'};
  fill: ${colors.neutral02};
  &:hover{
    background-color: ${({ disabled }) => disabled ? '#cfcfcf' : '#1c8320'};
  }
`

export const PaginationContainer = styled.section`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  button{
    width: fit-content;
  }
`

export const Checkbox = styled.div`
  gap: 5px;
  display: flex;

  input, label{
    cursor: pointer;
  }
`

export const Info = styled.div`
  display: flex;
  font-size: 14px;
  background-color: #e1edff;
  color: #6083b7;
  border-radius: 8px;
  padding: 10px;
  
  strong{
    display: contents;
  }
`

export const Year = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

export const stylesEdit: React.CSSProperties = {
  marginRight: '3px',
}