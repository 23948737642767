import styled from "styled-components"

export const Container = styled.div<{isSignature: boolean}>`
  max-width: 1170px;
  padding: 15px 30px;
  box-shadow: 0px 0 1px rgba(0,0,0,.18), 0px 0 1px rgba(0,0,0,.18);
  min-width: 240px;
  margin: 0 auto;
  ${({ isSignature }) => isSignature ? '' : 'height: 100vh;'}
  display: grid;
  gap: 15px;
  text-align: justify;
`
export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const BodyHTML = styled.section<{isSignature: boolean}>`
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${({ isSignature }) => isSignature ? '' : `
    min-height: calc(100vh - 185px);
    max-height: calc(100vh - 185px);
    border: 1px solid #ccc;
    border-radius: 10px;
  `}

  @media screen and (min-width: 700px)  {
    padding: 2rem;
  }

  .ql-editor{
    white-space: initial;
    padding: 0;
    height: auto;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  margin: 0;
`

export const TextSignature = styled.p`
  text-align: center;
  font-weight: bold;
  margin: 0;
`

export const HeaderSignature = styled.div`
  display: flex;
  max-width: max-content;
  margin: 0 auto 42px;

  img{
    width: 150px;
  }

  div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
  }
`