export const rules = {
  names: {
    required: true,
    minLength: 2,
  },
  dad_name: {
    required: true,
    minLength: 2,
  },
  mom_name: {
    required: true,
    minLength: 2,
  },
  gender: {
    required: true,
  },
  doc_type: {
    required: true,
  },
  doc_number: {
    required: true,
    minLength: 8,
    maxLength: 15,
  },
  date_of_birth: {
    required: true,
  },
  phone: {
    required: false,
    pattern: /^[0-9]*$/,
    minLength: 9,
    maxLength: 15,
  },
  email: {
    required: false,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
  },
  phone_emergency: {
    required: false,
    pattern: /^[0-9]*$/,
    minLength: 9,
    maxLength: 15,
  },
  relationship_emergency: {
    required: false,
    minLength: 2,
  },
  name_tutor: {
    required: false,
  },
  doc_type_tutor: {
    required: false,
  },
  doc_number_tutor: {
    required: false,
  },
  date_of_birth_tutor: {
    required: false,
  },
  email_tutor: {
    required: false,
  },
  phone_tutor: {
    required: false,
  },
  observations: {
    required: false,
  }
}

export const rulesYounger = {
  ...rules,
  phone: {
    required: false,
    pattern: /^[0-9]*$/,
    minLength: 9,
    maxLength: 15,
  },
  email: {
    required: false,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
  },
  name_tutor: {
    required: true,
    minLength: 2,
  },
  doc_type_tutor: {
    required: true,
  },
  doc_number_tutor: {
    required: true,
    minLength: 8,
    maxLength: 15,
  },
  date_of_birth_tutor: {
    required: true,
  },
  email_tutor: {
    required: true,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
  },
  phone_tutor: {
    required: true,
    pattern: /^[0-9]*$/,
    minLength: 9,
    maxLength: 15,
  },
}