import styled from "styled-components";

export const Container = styled.div`
  max-width: 1170px;
  padding: 30px;
  min-width: 240px;
  height: 100vh;
  margin: 0 auto;
  button{
    width: auto;
    margin: auto;
  }
`

export const Img = styled.img`
  width: 100%;
  height: calc(100% - 100px);
  object-fit: contain;
`