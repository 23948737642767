import { Button, Icon, Input, Layout, Question } from "components"
import * as colors from 'constants/colors'
import { returnGender, returnYears } from "constants/functions"
import {
  BUTTON_GENERATE_LINK_PATIENT,
  BUTTON_LINKS_PATIENT,
  LAYOUT_TITLE_PATIENT
} from "data/information"
import { useDebounce } from "hooks"
import younger from 'images/younger.png'
import IPatient, { IPatientList } from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { upperCase } from 'tools/functions'
import { AddDocument, LinkRegister, LinksPatient, PatientDetail, PatientEdit } from "./childrens"
import PatientDocuments from "./childrens/PatientDocuments"
import { handleExportExcel, listPatientsPaging } from "./services"
import {
  ButtonExcel,
  Note,
  PaginationContainer,
  SearchContainer,
  Table,
  TableContainer,
  Td,
  Year,
  stylesEdit
} from "./styled"


const Patients = () => {
  const [loading, setLoading] = useState(false)
  const [loadingExcel, setLoadingExcel] = useState(false)

  const [modalLinkRegister, setModalLinkRegister] = useState(false)
  const [modalPatientLinks, setModalPatientLinks] = useState(false)
  const [modalPatientDetail, setModalPatientDetail] = useState(false)
  const [modalAddDocument, setModalAddDocument] = useState(false)
  const [modalDocument, setModalDocument] = useState(false)
  const [modalQuestion, setModalQuestion] = useState(false)
  const [modalPatientEdit, setModalPatientEdit] = useState(false)

  const [patients, setPatients] = useState<IPatientList[]>([])
  const [patientDetail, setPatientDetail] = useState<IPatient>({} as IPatient)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [filters, setFilters] = useState({
    search: '',
    startDate: '',
    endDate: '',
  })
  const searchDebounce = useDebounce(filters.search, 1000)
  const pageDebounce = useDebounce(page, 1000)

  const handlePatientDetail = (patient: IPatient) => {
    setPatientDetail(patient)
    setModalPatientDetail(true)
  }

  const handlePatientEdit = (patient: IPatient) => {
    setPatientDetail(patient)
    setModalPatientEdit(true)
  }

  const handleDocument = (patient: IPatient) => {
    setPatientDetail(patient)
    setModalDocument(true)
  }

  const handleAddDocument = (patient: IPatient) => {
    setPatientDetail(patient)
    setModalAddDocument(true)
  }

  const getPatients = () => {
    listPatientsPaging(
      setLoading,
      setPatients,
      page,
      setTotal,
      setTotalPages,
      filters,
    )
  }
  
  useEffect(() => {
    if (page < 1 || page > totalPages) return
    getPatients()
  }, [pageDebounce, searchDebounce, filters.startDate, filters.endDate])

  return (
    <>
      <Layout title={LAYOUT_TITLE_PATIENT}>
        <SearchContainer>
          <Input
            type="search"
            placeholder="Buscar por nombres, número de documento, correo o celular"
            label="Buscar"
            value={filters.search}
            onChange={(search) => {
              setPage(1)
              setTotalPages(1)
              setFilters({ ...filters, search })
            }}
            className="filter-input search-input"
          />

          <Input
            type="date"
            label="Desde"
            maxDate={filters.endDate}
            value={filters.startDate}
            onChange={(startDate) => setFilters({ ...filters, startDate })}
            className="filter-input"
          />

          <Input
            type="date"
            label="Hasta"
            minDate={filters.startDate}
            value={filters.endDate}
            onChange={(endDate) => setFilters({ ...filters, endDate })}
            className="filter-input"
          />

          <Button
            value={BUTTON_GENERATE_LINK_PATIENT}
            onClick={() => setModalLinkRegister(!modalLinkRegister)}
            className="filter-button"
          />
          <Button
            value={BUTTON_LINKS_PATIENT}
            onClick={() => setModalPatientLinks(!modalPatientLinks)}
            className="filter-button"
          />
          <ButtonExcel
            disabled={loadingExcel || patients?.length === 0}
            onClick={() => handleExportExcel({ loadingExcel, patients, setLoadingExcel, filters })}
            className="filter-button"
          >
            <Icon
              name="excel"
              pointer
            />
          </ButtonExcel>
        </SearchContainer>

        <Note>
          <strong>IMPORTANTE:</strong> Los filtros de fecha afectarán la descarga del excel
        </Note>

        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Tipo de doc.</th>
                <th>Número de doc.</th>
                <th>Paciente</th>
                <th>Edad</th>
                <th>Género</th>
                <th>E-mail</th>
                <th>Teléfono</th>
                <th>Celular de emergencia</th>
                <th align="center">Detalle</th>
                <th align="center">Documento</th>
              </tr>
            </thead>
            <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan={11}>cargando...</td>
                  </tr>
                ) : (
                  <>
                    {patients?.length === 0 && (
                      <tr>
                        <td colSpan={11}>No hay pacientes registrados</td>
                      </tr>
                    )}
                    {patients?.map((patient, index: number) => (
                      <tr key={index}>
                        <td>{upperCase(patient.doc_type)}</td>
                        <td>{patient.doc_number}</td>
                        <td>{patient.names} {patient.dad_name} {patient.mom_name}</td>
                        <td align="center">
                          <Year>
                            {returnYears(patient.date_of_birth)} {patient.is_younger ? <img src={younger} width={20} /> : ''}
                          </Year>
                        </td>
                        <td align="center">{returnGender(patient.gender)}</td>
                        <td>{patient?.email || 'Ninguno'}</td>
                        <td>{patient?.phone || 'Ninguno'}</td>
                        <td>{patient.phone_emergency
                          ? `${patient.phone_emergency} ${patient.relationship_emergency ? `(${patient.relationship_emergency})` : ''}`
                          : 'Ninguno'}
                        </td>
                        <Td align="center">
                          <Icon
                            name="edit"
                            style={stylesEdit}
                            fill={colors.primary03}
                            onClick={() => handlePatientEdit(patient)}
                            pointer
                          />
                          <Icon
                            name="detail"
                            fill={patient.observations ? colors.primary03 : '#838383'}
                            onClick={() => handlePatientDetail(patient)}
                            pointer
                          />
                        </Td>
                        <Td align="center">
                          <Icon
                            name="add"
                            fill={colors.primary03}
                            onClick={() => handleAddDocument(patient)}
                            pointer
                          />
                          <Icon
                            name="document"
                            fill={patient.hasDocs ? colors.primary03 : '#838383'}
                            onClick={() => handleDocument(patient)}
                            pointer
                          />

                        </Td>
                      </tr>
                    ))}
                  </>
                )
              }
            </tbody>
          </Table>

          {
            !loading && patients.length > 0 && (
              <PaginationContainer>
                <p style={{ marginRight: 'auto' }}>
                  Total: {total}
                </p>

                <Button
                  onClick={() => {
                    if (page > 1 && page <= totalPages) {
                      setPage(page - 1)
                    } else {
                      setPage(1)
                    }
                  }}
                  value="Anterior"
                />

                <span>
                  <input
                    style={{
                      width: 60,
                      marginRight: 10,
                    }}
                    type="number"
                    value={page}
                    onChange={(e) => {
                      setPage(Number(e.target.value))
                    }}
                    min={1}
                    max={totalPages}
                  />
                  /
                  {totalPages}
                </span>
                <Button
                  onClick={() => {
                    if (page >= 1 && page < totalPages) {
                      setPage(page + 1)
                    } else {
                      setPage(totalPages)
                    }
                  }}
                  value="Siguiente"
                />
              </PaginationContainer>
            )
          }
        </TableContainer>

      </Layout>

      <LinkRegister
        open={modalLinkRegister}
        onClose={() => setModalLinkRegister(!modalLinkRegister)}
      />

      <LinksPatient
        open={modalPatientLinks}
        onClose={() => setModalPatientLinks(!modalPatientLinks)}
        setModalQuestion={setModalQuestion}
      />

      <PatientDetail
        open={modalPatientDetail}
        onClose={() => setModalPatientDetail(!modalPatientDetail)}
        patient={patientDetail}
      />

      <PatientDocuments
        open={modalDocument}
        onClose={() => setModalDocument(!modalDocument)}
        patient={patientDetail}
      />

      <AddDocument
        open={modalAddDocument}
        onClose={() => setModalAddDocument(!modalAddDocument)}
        patient={patientDetail}
      />

      <PatientEdit
        open={modalPatientEdit}
        onClose={() => setModalPatientEdit(!modalPatientEdit)}
        patient={patientDetail}
        callback={getPatients}
      />

      <Question
        open={modalQuestion}
        onClose={() => setModalQuestion(!modalQuestion)}
        description={`¿Estás seguro de eliminar el documento (${patientDetail?.doc_type || ''}) ${patientDetail?.doc_number || ''}?`}
        onConfirm={() => { }}
      // onConfirm={() => handleDelete(openDetails?.name || '')}
      // loading={loadingDelete}
      />
    </>
  )
}

export default Patients