import { Button, Dropdown, HtmlEditor, Input, Modal, editorConfig } from "components";
import { returnHTML } from "constants/functions";
import { DOCUMENT_NAME, DOCUMENT_TYPE, ERROR_DOCUMENT_NAME, ERROR_DOCUMENT_TYPE } from "data/form";
import { TYPE_DOCUMENTS } from "data/typeDocuments";
import { IDocument } from "interfaces";
import { stylesModal } from "pages/Documents/styled";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { rules } from "./rules";
import { createDocument } from "./services";
import { FormContainer, Info } from "./styled";

const Add = ({
  open,
  onClose,
  callbackList,
}: {
  open: boolean,
  onClose: () => void,
  callbackList: () => void,
}) => {
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDocument>({
    mode: 'onChange',
  })

  useEffect(() => {
    if (!open) return

    reset({
      name: '',
      type: 'unique',
      html: '',
    })
  }, [open])

  const onSubmit = async (data: IDocument) => {
    data.html = returnHTML(data.html)
    createDocument({ data, setLoading, onClose, callbackList })
  }

  if (!open) return null

  return (
    <Modal
      style={stylesModal}
      onClose={onClose}
      open={open}
      title='Agregar documento'
      closeInClickOut={true}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Info>
          <strong>Nota: </strong> El nombre del documento solo lleva letras y números, sin tildes, ni espacios, ni caracteres especiales.
        </Info>

        <Controller
          control={control}
          name='name'
          rules={rules.name}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              type='text'
              placeholder={DOCUMENT_NAME}
              label={DOCUMENT_NAME}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.name && ERROR_DOCUMENT_NAME}
            />
          )}
        />

        <Controller
          control={control}
          name='type'
          rules={rules.type}
          render={({ field: { value, onChange, onBlur } }) => (
            <Dropdown
              options={TYPE_DOCUMENTS}
              placeholder={DOCUMENT_TYPE}
              label={DOCUMENT_TYPE}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.type && ERROR_DOCUMENT_TYPE}
            />
          )}
        />

        <Controller
          control={control}
          name='html'
          rules={rules.html}
          render={({ field: { value, onChange, onBlur } }) => (
            <HtmlEditor
              modules={editorConfig.modules}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Button
          loading={loading}
          value="Agregar"
          type="submit"
        />
      </FormContainer>
    </Modal>
  );
}

export default Add;