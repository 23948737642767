export const rules = {
  typeDocument: {
    required: true,
  },
  numberDocument: {
    required: true,
  },
  isYounger: {
    required: false,
  },
}