export const returnYears = (date: string | undefined) => {
  if (!date) return '-'

  const today = new Date()
  const dateUTC = new Date(date)
  const birthDate = new Date(dateUTC.getTime() + dateUTC.getTimezoneOffset() * 60000)

  let years = today.getFullYear() - birthDate.getFullYear()
  const months = today.getMonth() - birthDate.getMonth()
  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    years--
  }
  return years
}

export const returnMaxDate = (yearsRemoved: number) => {
  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - yearsRemoved))
  const formatDate = `${maxDate.getFullYear()}-${(maxDate.getMonth() + 1).toString().padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`

  return formatDate;
}

export const returnFormatDateLocal = (date: string) => {
  const fechaHora = new Date(date).toLocaleString();

  return fechaHora;
}

export const returnFormatDateTime = (date: string) => {
  const formatDate = new Date(date).toLocaleDateString(
    'es',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    }
  )

  return formatDate;
}

export const returnGender = (gender: string) => {
  if (gender === 'M') return 'Masculino'
  if (gender === 'F') return 'Femenino'
  return 'Otro'
}

export const returnDateNow = (_date?: string) => {
  const date = _date ? new Date(_date) : new Date()
  const formatDate = date.toLocaleDateString(
    'es',
    {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }
  )

  return formatDate;
}

export const returnTimeNow = (_date?: string) => {
  const date = _date ? new Date(_date) : new Date()
  const formatTime = date.toLocaleTimeString(
    'es',
    {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h12',
    }
  )
  return formatTime;
}

export const returnHTML = (html: string) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <style>
      body {
          font: 14px/22px 'Hind', sans-serif;
      }
    </style>
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="Web site created using create-react-app" />
      <link rel="stylesheet" href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css"/>
    </head>
    
    <body class="ql-editor">
      ${html}
    </body>
    
    </html>`
}
