import { IOption } from "./props"

export const ConvertToDropdownArray = (
  array: any[],
  key: string,
  value: string,
): IOption[] => {
  if (!array) return []

  return array.map(item => {
    return {
      value: item[key],
      label: item[value],
    }
  })
}

export const ConvertToDropdown = (
  obj: any,
  key: string,
  value: string,
): IOption => {
  if (!obj) return { value: '', label: '' }

  return {
    value: obj[key],
    label: obj[value],
  }
}