import { Modal, Textarea } from "components"
import { returnYears } from "constants/functions"
import IPatient from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { upperCase } from 'tools/functions'
import { Container, ItemTab, TabContainer, stylesModal } from "./styled"

const PatientDetail = ({
  onClose,
  open,
  patient
}: {
  onClose: () => void
  open: boolean
  patient: IPatient
}) => {
  const [option, setOption] = useState(1)
  const returnGender = (gender: string) => {
    if (gender === 'M') return 'Masculino'
    if (gender === 'F') return 'Femenino'
    return 'Otro'
  }

  const returnStep = () => {
    switch (option) {
      case 1:
        return <ReturnStepOne />
      case 2:
        return <ReturnStepTwo />
      case 3:
        return <ReturnStepThree />
      default:
        break
    }
  }

  const ReturnStepOne = () => (
    <>
      <p><strong>Nombre:</strong> {patient?.names}</p>
      <p><strong>Apellido Paterno:</strong> {patient?.dad_name}</p>
      <p><strong>Apellido Materno:</strong> {patient?.mom_name}</p>
      <p><strong>Tipo de documento:</strong> {upperCase(patient?.doc_type)}</p>
      <p><strong>Número de documento:</strong> {patient?.doc_number}</p>
      <p><strong>Fecha de nacimiento:</strong> {patient?.date_of_birth}</p>
      <p><strong>Edad:</strong> {returnYears(patient?.date_of_birth)}</p>
      <p><strong>Email:</strong> {patient?.email || 'Ninguno'}</p>
      <p><strong>Celular:</strong> {patient?.phone || 'Ninguno'}</p>
      <p><strong>Género:</strong> {returnGender(patient?.gender)}</p>
      <p><strong>Fecha de registro:</strong> {patient?.created_at && new Date(patient?.created_at).toLocaleString()}</p>
      <p><strong>Celular de emergencia:</strong> {patient?.phone_emergency || 'Ninguno'}</p>
      <p><strong>Relación con el paciente:</strong> {patient?.relationship_emergency || 'Ninguno'}</p>
    </>
  )

  const ReturnStepTwo = () => (
    <>
      <p><strong>Nombre completo:</strong> {patient?.name_tutor}</p>
      <p><strong>Tipo de documento:</strong> {upperCase(patient?.doc_type_tutor)}</p>
      <p><strong>Número de documento:</strong> {patient?.doc_number_tutor}</p>
      <p><strong>Fecha de nacimiento:</strong> {patient?.date_of_birth_tutor}</p>
      <p><strong>Edad:</strong> {returnYears(patient?.date_of_birth_tutor)}</p>
      <p><strong>Email:</strong> {patient?.email_tutor}</p>
      <p><strong>Celular:</strong> {patient?.phone_tutor}</p>
    </>
  )

  const ReturnStepThree = () => (
    <>
      <Textarea
        type="text"
        placeholder={'Observaciones'}
        label={'Observaciones'}
        height={315}
        value={patient?.observations || 'No hay observaciones'}
        // onChange={onChange}
        // onBlur={onBlur}
        readOnly={true}
      />
    </>
  )

  useEffect(() => {
    if (!open) return
    setOption(1)
  }, [open])

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={stylesModal}
      title={`Detalle de ${patient?.names} ${patient?.dad_name}`}
      closeInClickOut>
      <TabContainer>
        <ItemTab
          isActive={option === 1}
          onClick={() => setOption(1)}>
          Paciente
        </ItemTab>
        {!!patient?.is_younger &&
          <ItemTab
            isActive={option === 2}
            onClick={() => setOption(2)}>
            Tutor
          </ItemTab>
        }
        <ItemTab
          isActive={option === 3}
          onClick={() => setOption(3)}>
          Observaciones
        </ItemTab>
      </TabContainer>

      <Container>
        {returnStep()}
      </Container>
    </Modal>
  )
}

export default PatientDetail