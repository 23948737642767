import Icon from 'components/Icon'
import { useLayoutEffect, useRef } from 'react'
import { IModal } from './props'
import { Body, Content, Header, Hr, ModalComponent, Title } from './styles'

const Modal = ({
  open,
  onClose,
  title,
  children,
  closeInClickOut,
  style,
}: IModal) => {
  const refContent = useRef<HTMLDivElement>(null)

  const onKeyDown = (e: KeyboardEvent) => {
    const isEscape = e.key === 'Escape'

    if (isEscape) onClose()
  }

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!closeInClickOut) return

    const isClickRight = e.button === 0
    const isOut = !(refContent?.current?.contains(e.target as Element))

    if (isClickRight && isOut) onClose()
  }

  useLayoutEffect(() => {
    if (open && closeInClickOut) {
      document.addEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = 'visible'
    }
  }, [open])

  if (!open) return null

  return (
    <ModalComponent
      onMouseDown={onMouseDown}
    >
      <Content
        ref={refContent}
        style={style}>
        <Header>
          <Title>{title}</Title>
          <Icon
            name="close"
            fill='#454545'
            onClick={onClose}
            pointer />
          <Hr />
        </Header>

        <Body>
          {children}
        </Body>
      </Content>
    </ModalComponent>
  )
}

Modal.defaultProps = {
  title: '',
  closeInClickOut: true,
}

export default Modal