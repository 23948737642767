export const names: string = '[[NOMBRES]]'
export const dad_name: string = '[[APELLIDO_PATERNO]]'
export const mom_name: string = '[[APELLIDO_MATERNO]]'
export const document_type: string = '[[TIPO_DOCUMENTO]]'
export const document_number: string = '[[NRO_DOCUMENTO]]'
export const age: string = '[[EDAD]]'
export const phone: string = '[[CELULAR]]'
export const email: string = '[[CORREO]]'

export const phone_emergency: string = '[[CELULAR_EMERGENCIA]]'
export const relationship_emergency: string = '[[PARENTESCO_EMERGENCIA]]'

export const name_tutor: string = '[[NOMBRE_TUTOR]]'
export const document_type_tutor: string = '[[TIPO_DOCUMENTO_TUTOR]]'
export const document_number_tutor: string = '[[NRO_DOCUMENTO_TUTOR]]'
export const age_tutor: string = '[[EDAD_TUTOR]]'
export const email_tutor: string = '[[CORREO_TUTOR]]'
export const phone_tutor: string = '[[CELULAR_TUTOR]]'

export const dateNow: string = '[[FECHA_ACTUAL]]'
export const timeNow: string = '[[HORA_ACTUAL]]'


