import * as userSlice from 'redux/slices/user';
import { AppDispatch } from "redux/store";

export const handleLogout = ({
  dispatch
}: {
  dispatch: AppDispatch,
}) => {
  localStorage.removeItem('token');
  dispatch(userSlice.setLogged(false))

}