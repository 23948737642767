import { Toast } from "components"
import * as links from 'constants/links'
import { UseFormSetValue } from "react-hook-form"
import { getDocuments, postDocument } from "./api"
import { IAddDocument } from "./props"

export const loadDocuments = async ({
  setLoading,
  setDocuments,
}: {
  setLoading: Function,
  setDocuments: Function,
}) => {
  try {
    setLoading(true)

    const response = await getDocuments()

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    const documentsFormated = response.documents.map((document: any) => ({
      ...document,
      value: String(document.id),
      label: document.name,
    }))

    setDocuments(documentsFormated)

  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}

export const createDocument = async ({
  patientId,
  documentId,
  html,
  setLoadingCreate,
  setValue
}: {
  patientId: number,
  documentId: number,
  html?: string,
  setLoadingCreate: Function,
  setValue: UseFormSetValue<IAddDocument>,
}) => {
  try {
    setLoadingCreate(true)

    const response = await postDocument({
      patientId,
      documentId,
      html,
    })

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setValue('link', `${links?.generateLinkPatientDocument}${response?.uuid}` || '')

    Toast({
      type: 'success',
      message: 'Documento creado correctamente'
    })
  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })

  } finally {
    setLoadingCreate(false)
  }
}