export const baseUrl: string = '/admin'

export const login: string = `${baseUrl}/login`
export const profile: string = `${baseUrl}/profile`
export const patients: string = `${baseUrl}/patients`
export const notFound: string = `/not-found`
export const documents: string = `${baseUrl}/documents`

export const registerPatient: string = '/register'
export const registerSignature: string = '/terms-and-conditions'

export const mga: string = 'https://consultoriomga.com/'

export const generateLinkPatient: string = `/register?t=`

export const generateLinkPatientDocument: string = `/terms-and-conditions?t=`