import * as colors from 'constants/colors';
import styled from "styled-components";

export const SearchContainer = styled.section`
  display: grid;
  align-items: end;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  gap: 5px;
  margin: 20px 0 10px;

  input{
    height: 40px;
  }

  .filter-input,
  .filter-button{
    width: 100%;
  }
  
  
  @media (min-width: 769px) {
    grid-template-columns: 1fr auto auto;

    .filter-button{
      width: auto;
    }
  }
`

export const TableContainer = styled.section`
  overflow: auto;
  min-width: 200px;
  margin: 30px 0 0 0;
`

export const Table = styled.table`
  width: 100%;
  border: 1px solid #bfbfbf;
  border-collapse: collapse;
  border-radius: 10px;
  td, th{
    padding: 10px;
    border: 1px solid #bfbfbf;
    border-collapse: collapse;
    vertical-align: middle;
  }
`

export const PaginationContainer = styled.section`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  button{
    width: fit-content;
  }
`

export const stylesModal: React.CSSProperties = {
  width: '90%',
  maxWidth: '1024px',
}


export const ButtonDelete = styled.button`
  cursor: pointer;
  fill: #e75757;
  background: #fff;
  margin: 0 0 0 10px;
  &:hover{
    fill: #e36969;
  }
`
export const ButtonEdit = styled.button`
  cursor: pointer;
  background: #fff;
  fill: ${colors.primary03};
  &:hover{
    fill: #2086c9;
  }
`

export const ButtonLegend = styled.button`
  padding: 10px;
  max-height: 40px;
  height: 100%;
  display: grid;
  place-items: center;
  background: #939090;
  fill: #fff;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background: #bfbfbf;
  }
`