import * as colors from 'constants/colors'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0 0 0;
  position: relative;
  font-size: 14px;
`

export const SelectedContainer = styled.section<{
  focus: boolean
  error: boolean
}>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
  padding: 10px;
  min-height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${({ error }) => error ? colors.error : colors.neutral01};
  cursor: pointer;
  overflow: hidden;
`

export const Input = styled.input`
  height: 100%;
  padding: 10px;
  border: none;
  outline: none;
`

export const OptionContainer = styled.ul<{ length: number }>`
  margin: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  max-height: calc(40px * ${({ length }) => length > 6 ? 6 : length});
  overflow: hidden;
  background-color: ${colors.neutral02};
  box-shadow: 0px 8px 16px 6px rgba(0,0,0,0.2);
  border-radius: 5px;
  list-style: none;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #b2b9c4;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #b2b9c4;
  }
`

export const Option = styled.li`
  display: block;
  width: 100%;
  justify-items: center;
  padding: 10px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  &:hover,
  &.selected{
    background-color: #f2f2f2;
  }
  &.selected,
  strong{
    color: ${colors.primary01};
  }
`

export const Label = styled.label<{
  focus: boolean
  error: boolean
}>`
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
  padding: 0 4px;
  font-size: 13px;
  font-weight: 500;
  background-color: ${colors.neutral02};
`

export const Placeholder = styled.span`
  display: grid;
  align-items: center;
  height: auto;
  font-size: 14px;
  color: #a3a3a3;
`

export const Error = styled.span`
  padding: 0 10px;
  font-size: 13px;
  color: ${colors.error};
`

export const SearchContainer = styled.li`
  padding: 5px 10px 5px 10px;
  width: 100%;
`
export const Content = styled.div`
  width: 100%;
  overflow: auto;
  height: 195px;
`

export const Search = styled.input`
  width: 100%;
  padding: 8px;
  outline: none;
  border-radius: 5px;
  border: 1px solid ${colors.primary02};
`