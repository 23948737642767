import styled from "styled-components"

export const Container = styled.div`
  p{
    margin: 0;
  }
`

export const ListDocuments = styled.ul`
  padding: 10px 0;
  display: grid;
  margin: 0;
  gap: 5px;
  height: 340px;
  overflow-y: auto;
  align-items: flex-start;
`

export const ItemDocument = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7px;
  border-bottom: 1px solid #ccc;
  flex-flow: wrap;
`

export const DetailDocument = styled.section`
  display: flex;
  align-items: center;
  gap: 5px; 
`

export const stylesModal: React.CSSProperties = {
  height: '100%',
  width: '100%',
  maxWidth: '650px',
  maxHeight: '500px',
}

export const Loading = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  min-height: 180px;
`

export const ButtonDelete = styled.button`
  cursor: pointer;
  fill: #e75757;
  background: #fff;
`

export const ButtonPDF = styled.button`
  cursor: pointer;
  fill: #e75757;
  background: #fff;
`

export const ButtonCopy = styled.button`
  cursor: pointer;
  padding: 7px;
  cursor: pointer;
  background: #ccc;
  &:hover{
    background: #bbb;
  }
`