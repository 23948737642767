import { useState, useEffect } from 'react'
import Icons from './Icons'
import { IOptions, TToast } from '../props'

const colors = {
	success: '#6EC05F',
	info: '#1271EC',
	error: '#D60A2E',
}

type CToastProps = IOptions & {
	id: number
	type: TToast
	message: string
	onHide: Function
	hideAfter: number
}

const Toast = ({
  id,
  heading,
  message,
  type,
  onHide,
  hideAfter,
  position
}: CToastProps) => {
	const place = (position).includes('bottom') ? 'Bottom' : 'Top'

	const marginType = `margin${place}`

	const borderLeft = `3px solid ${colors[type]}`
  
	const [animStyles, setAnimStyles]= useState<any>({ opacity: 0, [marginType]: -12 })

	const style = {
		paddingLeft: heading ? 25 : undefined,
		minHeight: heading ? 50 : undefined,
		borderLeft,
		...animStyles,
	}

	const handleHide = () => {
		setAnimStyles({ opacity: 0, [marginType]: '-12px' })

		setTimeout(() => {
			onHide(id, position)
		}, 300)
	}

	useEffect(() => {
		const animTimeout = setTimeout(() => {
			setAnimStyles({ opacity: 1, [marginType]: '12px' })
		}, 50)

		let hideTimeout: any = null

		if (hideAfter !== 0) {
			hideTimeout = setTimeout(() => {
				handleHide()
			}, hideAfter * 1000)
		}

		return () => {
			clearTimeout(animTimeout)
			if (hideTimeout) clearTimeout(hideTimeout)
		}
	}, [])

	return (
		<div
			className='ct-toast'
			role='alert'
			style={style}
      onClick={handleHide}
		>
			{Icons[type]?.()}

			<div className={heading ? 'ct-text-group-heading' : 'ct-text-group'}>
				{heading && <h4 className="ct-heading">{heading}</h4>}
				<div className="ct-text">{message}</div>
			</div>
		</div>
	)
}

export default Toast