export const ERROR: string = 'Campo requerido'

export const NAMES: string = 'Nombre'
export const ERROR_NAMES_LENGTH: string = 'El nombre debe tener al menos 2 caracteres'

export const DAD_NAME: string = 'Apellido paterno'
export const ERROR_DAD_NAME_LENGTH: string = 'El apellido paterno debe tener al menos 2 caracteres'

export const MOM_NAME: string = 'Apellido materno'
export const ERROR_MOM_NAME_LENGTH: string = 'El apellido materno debe tener al menos 2 caracteres'

export const DOC_TYPE: string = 'Tipo de documento'
export const ERROR_DOC_TYPE_LENGTH: string = 'Debe seleccionar un tipo de documento'

export const DOC_NUMBER: string = 'Número de documento'
export const ERROR_DOC_NUMBER_LENGTH: string = 'El número de documento debe tener al menos 8 caracteres'

export const GENDER: string = 'Género'
export const ERROR_GENDER_REQUIRED: string = 'Debe seleccionar un género'

export const DATE_OF_BIRTH: string = 'Fecha de nacimiento'

export const PHONE: string = 'Celular'
export const ERROR_PHONE_LENGTH: string = 'El celular debe tener 9 caracteres'

export const MAIL: string = 'Email'
export const ERROR_MAIL_PATTERN: string = 'El email no es válido'

export const NAME_TUTOR: string = 'Nombre completo'
export const ERROR_NAME_TUTOR_LENGTH: string = 'El nombre completo no es válido'

export const DOC_TYPE_TUTOR: string = 'Tipo de documento'
export const ERROR_DOC_TYPE_TUTOR_LENGTH: string = 'Debe seleccionar un tipo de documento'

export const DOC_NUMBER_TUTOR: string = 'Número de documento'
export const ERROR_DOC_NUMBER_TUTOR_LENGTH: string = 'El número de documento debe tener al menos 8 caracteres'

export const PHONE_EMERGENCY: string = 'Celular de emergencia (OPCIONAL)'
export const ERROR_PHONE_EMERGENCY_LENGTH: string = 'El celular de emergencia debe tener 9 caracteres'

export const RELATIONSHIP_EMERGENCY: string = 'Parentesco (OPCIONAL)'
export const ERROR_RELATIONSHIP_EMERGENCY_LENGTH: string = 'El parentesco no es válido'

export const OBSERVATIONS: string = 'Observaciones (OPCIONAL)'

export const CODE: string = 'Código'
export const ERROR_CODE_LENGTH: string = 'El código debe tener 6 caracteres'
export const CODE_PLACEHOLDER: string = 'Ingresa el código'

export const ACCEPT_TERMS: string = 'Acepto los términos y condiciones'
export const ERROR_ACCEPT_TERMS_REQUIRED: string = 'Debe aceptar los términos y condiciones'

export const REGISTER_BUTTON: string = 'Registrarse'
export const EDIT_BUTTON: string = 'Editar'

export const USERNAME: string = 'Nombre de usuario'
export const ERROR_USERNAME: string = 'El nombre de usuario es requerido'

export const PASSWORD: string = 'Contraseña'
export const ERROR_PASSWORD: string = 'La contraseña es requerida'

// document

export const DOCUMENT_NAME: string = 'Nombre del documento'
export const ERROR_DOCUMENT_NAME: string = 'El nombre del documento es requerido'

export const DOCUMENT_TYPE: string = 'Tipo de documento'
export const ERROR_DOCUMENT_TYPE: string = 'El tipo de documento es requerido'

export const DOCUMENT_HTML: string = 'Contenido del documento'
export const ERROR_DOCUMENT_HTML: string = 'El contenido del documento es requerido'