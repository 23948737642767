import * as colors from 'constants/colors';
import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  display: grid;
  grid-auto-rows: min-content;
  gap: 15px;
  padding: 30px 30px 50px 30px;
  max-width: 1170px;
  box-shadow: 0px 0 1px rgba(0,0,0,.18), 0px 0 1px rgba(0,0,0,.18);
  min-width: 240px;
  text-align: justify;
  height: 100vh;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  button{
    width: 200px;
  }
`

export const Text = styled.p`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 10px;
  color: ${colors.primary01};
  text-align: center;
`
export const Footer = styled.div`
  display: grid;
  gap: 10px;
  margin: 30px 0 0 0;
`
export const Logo = styled.img`
  margin: 0 auto 16px;
  display: block;
`
