import styled from 'styled-components'
import ITextarea from './props'

export const ComponentTextarea = styled.textarea<ITextarea>`
  background-color: ${(props) => props.bg};
  border: 1px solid ${({ theme, error }) => (error ? theme.inputBorderColorError : theme.inputBorderColor)};
  border-radius: 5px;
  color: ${(props) => props.color};
  height: ${({ height }) => height}px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif; 
  resize: none;
  &::placeholder { 
    color: ${({ theme }) => theme.inputPlaceholderColor};
  }
  &:focus {
    border-color: ${({ theme, error }) => error ? theme.inputBorderColorError : theme.inputBorderColorFocus};
    & + label {
      color: ${({ theme, error }) => error ? theme.inputLabelColorError : theme.inputLabelColorFocus};
    }
  }
`

export const Container = styled.div`
  position: relative;
  padding: 10px 0 0 0;
`

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
  padding: 0 4px;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  color: ${({ theme }) => theme.inputLabelColor};
`

export const Error = styled.span`
  padding: 0 10px;
  font-size: 13px;
  color: ${({ theme }) => theme.inputColorError};
`