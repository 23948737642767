import styled from "styled-components"

export const Container = styled.div`
`

export const ListLinks = styled.ul`
  display: grid;
  gap: 10px;
  margin: 10px 0 0 0;
  height: 330px;
  list-style: none;
  padding: 0;
  overflow-y: auto;
`

export const ItemLink = styled.li`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 5px;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ccc;
`

export const ButtonDelete = styled.button`
  cursor: pointer;
  fill: #e75757;
  background: #fff;
`

export const ButtonCopy = styled.button`
  cursor: pointer;
  padding: 7px;
  cursor: pointer;
  background: #ccc;
  &:hover{
    background: #bbb;
  }
`

export const Loading = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  min-height: 180px;
`

export const TitleItemLink = styled.h3`
  display: flex;
  align-items: center;
  gap: 3px;
  margin: 0;
  font-size: 15px;
`

export const stylesModal: React.CSSProperties = {
  height: '100%',
  maxHeight: '500px',
}