import * as links from 'constants/links'
import { useAppSelector } from 'hooks'
import { useEffect } from 'react'
import { Route as ComponentRoute, Redirect } from 'react-router-dom'
import { IRoute } from './props'

const Route = ({
  component: Component,
  isAuth,
  isPrivate,
  children,
  ...props
}: IRoute) => {
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    const pathname = window.location.pathname;
    let title = 'Consultorio Psicológico MGA';
    const titleBase = 'Consultorio Psicológico MGA';

    switch (pathname) {
      case links.login:
        title = 'Inicio sesión';
        break;
      case links.patients:
        title = 'Pacientes';
        break;
      case links.documents:
        title = 'Documentos';
        break;
      case links.registerPatient:
        title = 'Registrar paciente';
        break;
      case links.registerSignature:
        title = 'Firma documento';
        break;
      default:
        title = 'Error 404';
        break;

    }

    document.title = `${title} - ${titleBase}`;
  }, [window.location.pathname]);

  const validateAuth = () => {
    if (!isLoggedIn) return <Component {...props} />
    return <Redirect to={links.patients} />
  }

  const validatePrivate = () => {
    if (isLoggedIn) return <Component {...props} />
    return <Redirect to={links.login} />
  }

  const validateRoute = () => {
    if (isAuth) return validateAuth()
    if (isPrivate) return validatePrivate()
    return <Component {...props} />
  }

  return <ComponentRoute {...props} render={validateRoute} children={children} exact />
}

Route.defaultProps = {
  exact: true,
}

export default Route
