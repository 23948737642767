import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
`
export const stylesButtonClose: React.CSSProperties = {
  backgroundColor: '#f44336',
  borderColor: '#f44336',
}

export const stylesButtonSave: React.CSSProperties = {
  backgroundColor: '#4caf50',
  borderColor: '#4caf50',
}