import { Button, HtmlEditor, Input, Modal, Toast, editorConfig } from "components"
import { returnHTML } from "constants/functions"
import { DOCUMENT_NAME, ERROR_DOCUMENT_NAME } from "data/form"
import { IDocument } from "interfaces"
import { stylesModal } from "pages/Documents/styled"
import { useEffect, useLayoutEffect, useState, } from "react"
import { Controller, useForm } from "react-hook-form"
import { editDocument } from "./services"
import { ButtonsContainer, FormContainer } from "./styles"

const Details = ({
  open,
  document,
  onClose,
  callbackList,
}: {
  open: boolean,
  onClose: () => void,
  callbackList: () => void,
  document: IDocument | null,
}) => {
  const [loadingEdit, setLoadingEdit] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDocument>({
    mode: 'onChange',
  })

  useEffect(() => {
    reset({})
  }, [open])

  useLayoutEffect(() => {
    if (!open) return

    initCall()
  }, [open])

  const initCall = async () => {
    try {
      if (!document?.path) return onClose()
      const response = await fetch(document.path, { cache: 'no-cache' })
      const htmlText = await response.text()

      const initBody = htmlText.indexOf('<body class="ql-editor">') + 24
      const endBody = htmlText.indexOf('</body>')

      const html = htmlText.substring(initBody, endBody)

      console.log(html)

      if (!html) throw new Error()

      reset({
        id: document?.id,
        name: document?.name,
        path: document?.path,
        type: document?.type,
        uuid: document?.uuid,
        html,
      })
    } catch {
      Toast({
        type: 'error',
        message: 'No se pudo obtener el contenido del documento',
      })
      onClose()
    }
  }

  const onSubmit = async (data: IDocument) => {
    data.html = returnHTML(data.html)
    editDocument({ data, setLoading: setLoadingEdit, onClose, callbackList })
  }

  if (!open) return null

  return (
    <Modal
      style={stylesModal}
      onClose={onClose}
      open={open}
      title='Editar documento'
      closeInClickOut={true}
    >
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='name'
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              type='text'
              placeholder={DOCUMENT_NAME}
              label={DOCUMENT_NAME}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.name && ERROR_DOCUMENT_NAME}
            />
          )}
        />

        <Controller
          control={control}
          name='html'
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <HtmlEditor
              modules={editorConfig.modules}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <ButtonsContainer>
          <Button
            loading={loadingEdit}
            value="Editar"
            type="submit"
          />
        </ButtonsContainer>

      </FormContainer>
    </Modal>
  );
}

export default Details;