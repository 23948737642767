import { Toast } from "components"
import { deleteDeleteDocument, getDocuments } from "./api"

export const listDocuments = async ({
  setLoading,
  setList,
  setTotal,
  setTotalPages,
  search,
  page,
}: {
  setLoading: Function,
  setList: Function,
  setTotal: Function,
  setTotalPages: Function,
  search: string,
  page: number,
}) => {
  try {
    setLoading(true)

    const response = await getDocuments(search, page)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setList(response.documents)
    setTotal(response.total)
    setTotalPages(Math.ceil(response.total / 10))
    
  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}

export const deleteDocument = async ({
  name,
  setLoading,
  callbackList,
}: {
  name: string,
  setLoading: Function,
  callbackList: Function
}) => {
  try {
    setLoading(true)

    const response = await deleteDeleteDocument(name)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    Toast({
      type: 'success',
      message: 'Documento eliminado correctamente'
    })
    callbackList()
  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}