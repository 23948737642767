import styled from 'styled-components'

export const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(4px);
  z-index: 999;
  opacity: 1;
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: min-content;
  margin: 10px auto;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: fit-content;
  max-height: 90%;
  overflow: hidden;

  @media (min-width: 360px) {
    width: 480px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 222;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.modalTextColor};
  width: 100%;
  margin: 0;
  font-size: 1.3em;
  text-align: center;
  color: #454545;
`

export const Body = styled.div`
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  overflow: auto;
  height: 100%;
  max-height: 80vh;
`

export const Close = styled.i`
  font-size: 1.5em;
  cursor: pointer;
`

export const Hr = styled.hr`
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.modalBorderColor};
  margin: 16px 0;
`
