
import Icon from 'components/Icon'
import { useEffect, useRef, useState } from 'react'
import {
  IDropdown,
  IOption
} from './props'
import {
  Container,
  Content,
  Error,
  Label,
  Option,
  OptionContainer,
  Placeholder,
  Search,
  SearchContainer,
  SelectedContainer
} from './styles'

const Dropdown = ({
  options,
  value,
  label,
  placeholder,
  onChange,
  onBlur,
  error,
  isSearch
}: IDropdown) => {
  const refDropdown = useRef<HTMLDivElement>(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [data, setData] = useState<IOption[]>(options)
  const [search, setSearch] = useState<string>('')

  const renderValue = () => {
    if (!value) return (
      <Placeholder>{placeholder}</Placeholder>
    );

    const obj = options.find((option) => option.value === value)
    return (
      <span>{obj?.label}</span>
    )

  }

  const returnOption = (option: IOption) => {
    const search_ = search ? search.toString().toUpperCase() : '';
    const ocurrence = search ? `<strong>${search_}</strong>` : '';
    const label = search ? option.label.toUpperCase().replace(new RegExp(search_, "g"), ocurrence) : option.label.toUpperCase();

    return (
      <Option
        className={option.value === value ? 'selected' : ''}
        key={option.value}
        onClick={() => changeValue(option)}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Option>
    )
  }

  const returnArrowSelected = () => {
    return (openDropdown
      ? (
        <Icon name='up' />
      )
      : (
        <Icon name='down' />
      )
    )
  }

  const changeValue = (option: IOption) => {
    onChange?.(option.value)
    onBlur?.(option.value)
    setOpenDropdown(!openDropdown)
  }

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!refDropdown.current || refDropdown.current.contains(event.target as Element)) {
        return
      }
      setOpenDropdown(false)
    }
    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)
    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [refDropdown, () => setOpenDropdown(false)])

  useEffect(() => {
    if (!openDropdown) return

    if (refDropdown.current) {
      const { top } = refDropdown.current.getBoundingClientRect()
      const element = refDropdown.current.querySelector('ul')

      if (!element) return

      const isBottom = window.innerHeight - top < 200
      if (isBottom) {
        element.style.bottom = `0`
      }
    }
    setSearch('')
  }, [openDropdown])

  // useEffect(() => {
  //   const ocurrences = options.filter((option) => option.label.toLowerCase().includes(value ? value.toString().toLowerCase() : ''))
  //   const noOcurrences = options.filter((option) => !option.label.toLowerCase().includes(value ? value.toString().toLowerCase() : ''))
  //   setData([...ocurrences, ...noOcurrences])
  // }, [value])

  useEffect(() => {
    const ocurrences = options.filter((option) => option.label.toLowerCase().includes(search ? search.toString().toLowerCase() : ''))
    const noOcurrences = options.filter((option) => !option.label.toLowerCase().includes(search ? search.toString().toLowerCase() : ''))
    setData([...ocurrences, ...noOcurrences])
  }, [search])

  return (
    <Container ref={refDropdown}>
      <SelectedContainer
        onClick={() => setOpenDropdown(!openDropdown)}
        focus={openDropdown}
        error={error ? true : false}
      >
        {renderValue()}
        {returnArrowSelected()}
      </SelectedContainer>

      {label && (
        <Label
          focus={openDropdown}
          error={error ? true : false}
        >{label}</Label>)}

      {
        openDropdown && (
          <OptionContainer length={data.length}>
            {isSearch && (
              <SearchContainer>
                <Search
                  type='search'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder='Buscar....'
                />
              </SearchContainer>
            )}
            <Content>
              {data.map((option) => returnOption(option))}
            </Content>
          </OptionContainer>
        )
      }

      {error && <Error>{error}</Error>}
    </Container>
  )
}

Dropdown.defaultProps = {
  error: '',
  isSearch: false,
}

export default Dropdown