import { API } from "constants/envs"
import { encryptRSA } from "tools/encryption"

export const getValidatePatient = async (
  code: string
) => {
  let url = `${API}/patient/get-uuid/?uuid=${code}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
  });

  const info = response.json();
  return info;
};

export const postRegisterPatient = async (
  patient: any
) => {
  let url = `${API}/patient/register`;

  const body = JSON.stringify({ payload: encryptRSA(JSON.stringify(patient)) });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
    body,
  });

  const info = response.json();
  return info;
}