// for users
export const TYPE_DOCUMENTS_USERS = [
  {
    label: 'DNI',
    value: 'dni',
  },
  {
    label: 'CE',
    value: 'ce',
  },
]

export const TYPE_DOCUMENTS = [
  {
    label: 'Único',
    value: 'unique',
  },
  {
    label: 'Plantilla',
    value: 'template',
  },
  {
    label: 'Plantilla - documentos',
    value: 'template-docs',
  }
]