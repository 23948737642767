export const rules = {
  name:{
    required: true,
    pattern: /^[a-zA-Z0-9]+$/,
  },
  type:{
    required: true,
  },
  html:{
    required: true,
  }
}