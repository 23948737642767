import { Toast } from "components";
import IPatient from "interfaces/patient.interface";
import { putPatient } from "./api";

export const updatePatient = async (data: IPatient, setLoading: Function, callback: Function) => {
  try {
    setLoading(true);

    const response = await putPatient(data)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    Toast({
      type: 'success',
      message: 'Paciente actualizado correctamente'
    })
    callback()
  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false);
  }
}