import * as colors from 'constants/colors'
import styled from "styled-components"

export const ItemTab = styled.button<{ isActive: boolean }>`
  border-color: ${({ isActive }) => isActive ? colors.primary03 : '#dfdfdf'};
  border-style: solid;
  border-bottom-width: 4px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  background-color: transparent;
  color: ${({ isActive }) => isActive ? colors.primary03 : '#ababab'};
  width: 120px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ isActive }) => isActive ? '600' : '500'};
  height: 40px;
`

export const Container = styled.div`
  padding: 20px 10px;
  color: #454545;
  height: calc(100% - 40px);
  p{
    margin: 0px;
  }

`

export const TabContainer = styled.div`
  
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 250px;
  resize: none;
  font-size: 14px;
  font-family: 'Roboto', sans-serif; 
`

export const stylesModal: React.CSSProperties = {
  maxHeight: '500px',
  height: '100%'
}
