import { API } from "constants/envs"

export const getDocuments = async (patientId: number) => {
  let url = `${API}/document/patient?id=${patientId}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const deleteDocumentPatient = async (documentId: number) => {
  let url = `${API}/document/patient`

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body: JSON.stringify({
      id: documentId,
    }),
  });

  const info = response.json();
  return info;
}