import { Toast } from "components"
import { IDocument } from "interfaces"
import { postCreateDocument } from "./api"

export const createDocument = async ({
  data,
  setLoading,
  onClose,
  callbackList,
}: {
  data: IDocument,
  setLoading: Function,
  onClose: Function,
  callbackList: Function
}) => {
  try{
    setLoading(true)

    const response = await postCreateDocument(data)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    onClose()
    Toast({
      type: 'success',
      message: 'Documento creado correctamente'
    })
    callbackList()
  }catch(error: any){
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  }finally{
    setLoading(false)
  }
}