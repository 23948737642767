import * as colors from 'constants/colors';
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  place-items: center;
  text-align: justify;
  margin: 0 auto;
  max-width: 400px;
  min-width: 200px;
  width: 100%;
  height: 100vh;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
  color: ${colors.primary01};
`

export const FormContainer = styled.form`
  padding: 30px;
  display: grid;
  gap: 15px;
  width: 100%;
  height: fit-content;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 10px;
`

export const Error = styled.span`
  padding: 0;
  font-size: 13px;
  color: ${colors.error};
  line-height: 1;
  width: 100%;
  display: inline-block;
`

export const Logo = styled.img`
  margin: 10px auto;
`