import { API } from "constants/envs";
import IPatient from "interfaces/patient.interface";
import { encryptRSA } from "tools/encryption";

export const putPatient = async (patient: IPatient) => {
  const url = `${API}/patient`;

  const body = JSON.stringify({ payload: encryptRSA(JSON.stringify(patient)) });

  console.log(patient);

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body,
  });

  const info = response.json();
  return info;
}
