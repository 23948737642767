import { CONGRATULATIONS_DESCRIPTION, CONGRATULATIONS_TITLE, CONSULTORIO_MGA } from "data/information"
import girlRegister from 'images/girl_register.jpg'
import logo_mga from 'images/logo_mga.png'
import { ButtonsContainer, Container, Footer, Text, Title } from "./styled"

const Congratulations = ({
  patientName
}: {
  patientName: string
}) => {

  return (
    <Container>
      <img src={logo_mga} height={80} />
      <Title>{CONGRATULATIONS_TITLE}</Title>

      <Footer>
        <Text dangerouslySetInnerHTML={{ __html: CONGRATULATIONS_DESCRIPTION(patientName) }} />
        <Text dangerouslySetInnerHTML={{ __html: CONSULTORIO_MGA }} />

        <ButtonsContainer>
          <img
            src={girlRegister}
            alt="icon"
            className="avatars"
          />
        </ButtonsContainer>
      </Footer>
    </Container>
  );
}

export default Congratulations;