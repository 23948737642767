import { Icon, Input, Modal } from "components"
import { generateLinkPatient } from "constants/links"
import younger from 'images/younger.png'
import IPatient from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { handleCopyClipboard, upperCase } from "tools/functions"
import { deleteLink, listLinks } from "./services"
import { ButtonCopy, ButtonDelete, Container, ItemLink, ListLinks, Loading, TitleItemLink, stylesModal } from "./styled"

const PatientLinks = ({
  onClose,
  open,
  setModalQuestion,
}: {
  onClose: () => void
  open: boolean
  setModalQuestion: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [links, setLinks] = useState<IPatient[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!open) return
    setSearch('')
    listLinks({ setLinks, setLoading })
  }, [open])

  const handleCopy = (link: string) => {
    const url = `${window.location.origin}${generateLinkPatient}${link}`
    handleCopyClipboard(url)
  }

  // onClick={async () => {
  //   await deleteLink(link.uuid_register, link.doc_type, link.doc_number)
  //   listLinks({ setLinks, setLoading })
  // }}

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={stylesModal}
      title='Lista de links de registro'
      closeInClickOut
    >
      {
        loading ? <Loading>Cargando...</Loading> : (
          <Container>
            <Input
              type="search"
              placeholder="Buscar por número de documento"
              label="Buscar"
              onChange={(value) => setSearch(value)}
              value={search}
            />
            <ListLinks>
              {
                (
                  !links.length ||
                  links?.filter(
                    (link: any) => link.doc_number?.includes(search)
                  ).length === 0
                ) && <p>No hay links registrados</p>
              }
              {
                links?.filter(
                  (link: any) => link.doc_number?.includes(search)
                ).map((link: any) => (
                  <ItemLink key={link.uuid_register}>
                    <TitleItemLink>
                      ({upperCase(link.doc_type)})
                      {' '}
                      {link.doc_number}
                      {link.is_younger ? <img src={younger} width={20} /> : ''}
                    </TitleItemLink>
                    <ButtonCopy
                      onClick={() => handleCopy(link.uuid_register)}
                    >
                      Copiar link
                    </ButtonCopy>

                    <ButtonDelete
                      onClick={async () => {
                        await deleteLink(link.uuid_register, link.doc_type, link.doc_number)
                        listLinks({ setLinks, setLoading })
                      }}
                    >
                      <Icon
                        name='delete'
                        pointer
                      />
                    </ButtonDelete>
                  </ItemLink>
                ))
              }
            </ListLinks>
          </Container>
        )
      }

    </Modal >
  )
}

export default PatientLinks