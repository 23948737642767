import { Link } from "react-router-dom"
import styled from "styled-components"

export const Checkbox = styled.div`
  gap: 5px;
  display: flex;

  input, label{
    cursor: pointer;
  }
`

export const Info = styled.div`
  display: flex;
  font-size: 14px;
  background-color: #e1edff;
  color: #6083b7;
  border-radius: 8px;
  padding: 10px;
  
  strong{
    display: contents;
  }
`

export const FormContainer = styled.form`
  display: grid;
  gap: 15px;
  margin: 15px 0;
`
export const CopyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  button{
    padding: 10px;
    cursor: pointer;
    background: #ccc;
    &:hover{
      background: #bbb;
    }
  }
`

export const LinkContainer = styled(Link)`
  text-decoration: underline;
  padding: 10px;
  color: #7b7b7b;
`

export const TitleLinkRegister = styled.p`
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
`