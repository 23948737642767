import { Loading } from 'components'
import * as links from 'constants/links'
import { useAppDispatch } from 'hooks'
import * as pages from 'pages'
import { Suspense, useLayoutEffect, useState } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import Route from './Route'
import { authValidate } from './services'

const Routes = () => {

  useLayoutEffect(() => {
    if (window.location.pathname?.includes('admin')) {
      const token = localStorage.getItem('token')
      if (token) {
        authValidate({ setLoading, dispatch })
      }
    }
  }, [])

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)

  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          <Suspense fallback={<div>Cargando</div>}>
            <BrowserRouter>
              <Switch>
                <Route isAuth path={links.login} component={pages.Login} />
                <Route isPrivate path={links.patients} component={pages.Patients} />
                <Route isPrivate path={links.documents} component={pages.Documents} />
                <Route path={links.registerPatient} component={pages.RegisterPatient} />
                <Route path={links.registerSignature} component={pages.RegisterSignature} />
                <Route path={links.notFound} component={pages.NotFound} />
                <Route path='/admin' children={<Redirect to={links.login} />} />
                <Route path='/' children={<Redirect to={links.baseUrl} />} />
                <Route path='*' component={pages.NotFound} />
              </Switch>
            </BrowserRouter>
          </Suspense>
        )
      }
    </>
  )
}

export default Routes
