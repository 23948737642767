import { Sidebar } from "components"
import { ILayout } from "./props"
import { Body, Container, Title } from "./styles"

const Layout = ({
  title,
  children
}: ILayout) => {
  return (
    <Container>
      <Sidebar />
      <Body>
        {title && (
          <Title>
            <h1>{title}</h1>
          </Title>
        )}
        {children}
      </Body>
    </Container>
  )
}

export default Layout