import { Toast } from "components"
import { deleteDocumentPatient, getDocuments } from "./api"

export const listDocuments = async ({
  setLoading,
  setDocuments,
  patientId
}: {
  setLoading: Function,
  setDocuments: Function,
  patientId: number,
}) => {
  try {
    setLoading(true)

    const response = await getDocuments(patientId)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    const documentsFormated = response.documents
    setDocuments(documentsFormated)

  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}

export const deleteDocument = async ({
  documentId,
  callback,
  setLoading,
}:{
  documentId: number,
  callback: Function,
  setLoading: Function,
}) => {
  try{
    setLoading(true)

    const response = await deleteDocumentPatient(documentId)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    Toast({
      type: 'success',
      message: 'Documento eliminado correctamente'
    })
    callback()
  }catch(error: any){
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  }finally{
    setLoading(false)
  }
}