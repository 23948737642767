import { Toast } from "components"
import { returnYears } from "constants/functions"
import { saveAs } from 'file-saver'
import * as XLSX from "xlsx"
import { getPatientsPaging, listPatients } from "./api"

export const listPatientsPaging = async (
  setLoading: Function,
  setStudents: Function,
  page: number,
  setTotal: Function,
  setTotalPages: Function,
  filters: { search: string, startDate: string, endDate: string }
) => {
  try {
    setLoading(true)
    const response = await getPatientsPaging(
      page,
      filters,
    )

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al recibir los datos, intente nuevamente')
    }

    setStudents(response.patients)
    setTotal(response.total)
    setTotalPages(Math.ceil(response.total / 20))

  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}

function s2ab(s: any) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
}

export const handleExportExcel = async ({
  setLoadingExcel,
  patients,
  loadingExcel,
  filters,
}: {
  setLoadingExcel: Function,
  patients: any[],
  loadingExcel: boolean,
  filters: { search: string, startDate: string, endDate: string }
}) => {
  try {
    if (loadingExcel || patients.length === 0) return
    setLoadingExcel(true)

    const response = await listPatients(filters)
    if (response.response !== 'success') throw new Error('Error al obtener los datos')

    // ========== EXCEL VERSION 1 ===========
    
    // const data = [
    //   ['TIPO DE DOC', 'NÚMERO DE DOC', 'EDAD', 'FECHA NACIMIENTO', 'GENERO', 'NOMBRES', 'APELLIDO PATERNO', 'APELLIDO MATERNO', 'EMAIL', 'CELULAR', 'FECHA REGISTRO', 'ES MENOR DE EDAD', 'NOMBRE DEL TUTOR', 'TIPO DE DOC TUTOR', 'NÚMERO DE DOC TUTOR', 'EDAD TUTOR', 'EMAIL TUTOR', 'CELULAR TUTOR', 'TELÉFONO DE EMERGENCIA', 'PARENTESCO']
    // ]

    // response.patients.forEach((patient: any) => {
    //   data.push([
    //     patient.doc_type,
    //     patient.doc_number,
    //     returnYears(patient.date_of_birth),
    //     formatDateExcel(patient.date_of_birth),
    //     patient.gender === 'M' ? 'Masculino' : patient.gender === 'F' ? 'Femenino' : 'Otro',
    //     patient.names,
    //     patient.dad_name,
    //     patient.mom_name,
    //     patient.email || '-',
    //     patient.phone || '-',
    //     new Date(patient.created_at).toLocaleString(),
    //     patient.is_younger ? 'SI' : 'NO',
    //     patient.name_tutor || '-',
    //     patient.doc_type_tutor || '-',
    //     patient.doc_number_tutor || '-',
    //     returnYears(patient.date_of_birth_tutor) || '-',
    //     patient.email_tutor || '-',
    //     patient.phone_tutor || '-',
    //     patient.phone_emergency || '-',
    //     patient.relationship_emergency || '-',
    //   ])
    // })

    // ========== EXCEL VERSION 2: MIGRACION CALENDARIO ===========

    const data = [
      ['Email', 'Nombres', 'Apellidos', 'DNI o RUC', 'Número de cliente', 'Teléfono', 'Teléfono secundario del cliente', 'Dirección', 'distrito', 'Ciudad', 'Edad', 'Género. 1 = Femenino, 2 = Masculino', 'Día del nacimiento', 'Mes del nacimiento', 'Año de nacimiento.', 'la_persona_que_se_atendera_es_menor_de_edad?_a34155', 'nombre_y_apellidos_del_tutor(a)_a34156', 'dni/ce_del_tutor(a)_a34188', 'parentesco_a34191', 'email_del_tutor(a)_a34189', 'telefono_del_tutor(a)_a34221', 'Fecha de registro'
      ]
    ]

    response.patients.forEach((patient: any) => {
      const dateOfBirthArray = patient.date_of_birth.split('-')

      data.push([
        patient.email,
        patient.names,
        `${patient.dad_name} ${patient.mom_name}`,
        patient.doc_number,
        '',
        patient.phone,
        '',
        '',
        '',
        '',
        returnYears(patient.date_of_birth),
        patient.gender === 'M' ? 2 : patient.gender === 'F' ? 1 : '',
        dateOfBirthArray[2],
        dateOfBirthArray[1],
        dateOfBirthArray[0],
        patient.is_younger ? 'SI' : 'NO',
        `${patient.name_tutor || ''} ${patient.dad_name_tutor || ''} ${patient.mom_name_tutor || ''}`,
        patient.doc_number_tutor || '',
        '',
        patient.email_tutor || '',
        patient.phone_tutor || '',
        new Date(patient.created_at).toLocaleString(),
      ])
    })
    
    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();

    // Agregar una nueva hoja al libro de Excel
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

    // Guardar el libro de Excel como archivo
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, `pacientes-${new Date().getTime()}.xlsx`);
  } catch (error: any) {
    Toast({
      type: 'error',
      message: error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
        ? 'Intente nuevamente'
        : error.message
    })
  } finally {
    setLoadingExcel(false)
  }
}