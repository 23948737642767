export const TITLE: string = 'Test Vocacional';

export const LOGIN_TITLE: string = 'Iniciar Sesión';

export const LOGIN_BUTTON: string = 'Ingresar';

export const LOADING_BUTTON: string = 'Cargando...';

export const VALIDATION_BUTTON: string = 'Validar'

export const LAYOUT_TITLE_PATIENT: string = 'Pacientes';

export const TITLE_REGISTER_PATIENT: string = '¡Bienvenido(a) al formulario de registro!';

export const DESCRIPTION_REGISTER_PATIENT: string = `Entendemos lo valioso que es tu tiempo, por lo que te pedimos le dediques unos minutos para llenar cada campo de manera <strong>precisa y completa,</strong> ya que a través de los datos que nos proporciones, podremos mantenernos en contacto contigo, generar consentimientos informados, comprobantes de pago, reportes, etc.
`

export const SUBTITLE_REGISTER_PATIENT: string = '<strong>Registra aquí los datos de la persona que será atendida:</strong>';

export const BUTTON_GENERATE_LINK_PATIENT: string = 'Generar link';

export const BUTTON_LINKS_PATIENT: string = 'Links';

export const CONGRATULATIONS_TITLE: string = 'Te has registrado correctamente';

export const CONGRATULATIONS_DESCRIPTION = (name: string) => `Felicitaciones <strong>${name}</strong> acabas de finalizar el <strong>registro del formulario</strong> con éxito, tus datos fueron grabados satisfactoriamente.`;

export const CONSULTORIO_MGA: string = '<strong>Consultorio Psicológico MGA.</strong>';

export const CONGRATULATIONS_GRATITUDE_BACK: string = 'Volver al inicio';

export const LINK: string = 'https://consultoriomga.com/test-vocacional/';

export const LAYOUT_TITLE_DOCS: string = 'Documentos';