import { Link } from "react-router-dom"
import styled from "styled-components"

export const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
  min-height: 560px;
`

export const FormContainer = styled.form`
  display: grid;
  gap: 15px;
`
export const stylesModal: React.CSSProperties = {
  width: '90%',
  maxWidth: '1024px',
}

export const CopyContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  button{
    padding: 10px;
    cursor: pointer;
    background: #ccc;
    &:hover{
      background: #bbb;
    }
  }
`

export const LinkContainer = styled(Link)`
  text-decoration: underline;
  padding: 10px;
  color: #7b7b7b;
`

export const TitleLinkRegister = styled.p`
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
`