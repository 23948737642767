import Icon from 'components/Icon'
import { useState } from 'react'
import IInput from './props'
import {
  ComponentInput,
  Container,
  Error,
  Label,
  styleIconPassword
} from './styles'

const Input = ({
  error,
  onChange,
  onBlur,
  name,
  label,
  placeholder,
  type,
  value,
  maxDate,
  minDate,
  disabled,
  className,
  min,
  checked,
  style,
}: IInput) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)
  const returnIcon = showPassword ? 'show-eye' : 'hide-eye'
  const returnPlaceholder = (type?.includes('password') && !showPassword) ? 'xxxxxxxx' : placeholder
  const returnType = showPassword ? 'text' : type

  return (
    <Container className={className}>
      <ComponentInput
        error={error}
        id={name}
        name={name}
        onChange={(e: any) => onChange?.(e.target.value)}
        onBlur={(e: any) => onBlur?.(e.target.value)}
        placeholder={returnPlaceholder}
        type={returnType}
        value={value}
        style={style}
        min={minDate && new Date(minDate).toISOString().split('T')[0] || min}
        max={maxDate && new Date(maxDate).toISOString().split('T')[0]}
        disabled={disabled}
        checked={checked}
      />

      {label && (
        <Label>{label}</Label>
      )}

      {type?.includes('password') && (
        <Icon
          name={returnIcon}
          onClick={toggleShowPassword}
          pointer
          style={styleIconPassword}
        />
      )}

      {error && <Error>{error}</Error>}
    </Container>
  )
}

Input.defaultProps = {
  error: '',
  type: 'text',
}

export default Input
