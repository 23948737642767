import { Toast } from "components"
import { IDocument } from "interfaces"
import { putEditDocument } from "./api"

export const editDocument = async ({
  data,
  setLoading,
  onClose,
  callbackList,
}: {
  data: IDocument,
  setLoading: Function,
  onClose: Function,
  callbackList: Function
}) => {
  try {
    setLoading(true)

    const response = await putEditDocument(data)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    onClose()
    Toast({
      type: 'success',
      message: 'Documento editado correctamente'
    })
    callbackList()
  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}