import { Icon, Input, Modal } from "components"
import { returnFormatDateTime } from "constants/functions"
import * as links from "constants/links"
import IPatient from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { handleCopyClipboard } from "tools/functions"
import { IPatientDocument } from "./props"
import { deleteDocument, listDocuments } from "./services"
import { ButtonCopy, ButtonDelete, ButtonPDF, Container, DetailDocument, ItemDocument, ListDocuments, Loading, stylesModal } from "./styled"

const PatientDocuments = ({
  open,
  onClose,
  patient,
}: {
  open: boolean,
  onClose: () => void,
  patient: IPatient
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [documents, setDocuments] = useState<IPatientDocument[]>([])

  useEffect(() => {
    if (!open || !patient?.id) return

    listDocuments({
      setLoading,
      setDocuments,
      patientId: patient.id,
    })
  }, [open])

  const handleCopy = (link: string) => {
    const url = `${window.location.origin}${links.generateLinkPatientDocument}${link}`
    handleCopyClipboard(url)
  }

  const returnWithoutSignature = (document: IPatientDocument) => {
    return (
      <>
        <span>{returnFormatDateTime(document?.created_at || '')}</span>
        <ButtonPDF
          onClick={() => {
            const link = `${window.location.origin}${links.generateLinkPatientDocument}${document.uuid}`
            window.open(link, '_blank')
          }}
        >
          <Icon
            name='file-pdf'
            fill="#3F51B5"
            pointer
          />
        </ButtonPDF>

        <ButtonDelete
          onClick={() => {
            console.log('delete')
            deleteDocument({
              documentId: document.id,
              setLoading,
              callback: () => {
                if(!patient?.id) return
                listDocuments({
                  setLoading,
                  setDocuments,
                  patientId: patient.id,
                })
              }
            })
          }}
        >
          <Icon
            name='delete'
            pointer
          />
        </ButtonDelete>
      </>
    )
  }

  const returnSignature = (document: IPatientDocument) => {
    return (
      <>
        <span>{returnFormatDateTime(document?.date_signature || '')}</span>
        <ButtonPDF
          onClick={() => {
            const link = `${window.location.origin}${links.generateLinkPatientDocument}${document.uuid}`
            window.open(link, '_blank')
          }}
        >
          <Icon
            name='file-pdf'
            fill="#3F51B5"
            pointer
          />
        </ButtonPDF>
      </>
    )
  }

  const returnNoSignature = (document: IPatientDocument) => {
    return (
      <>
        <ButtonCopy
          onClick={() => handleCopy(document.uuid)}
        >
          Copiar link
        </ButtonCopy>

        <ButtonDelete
          onClick={() => {
            console.log('delete')
            deleteDocument({
              documentId: document.id,
              setLoading,
              callback: () => {
                if(!patient?.id) return
                listDocuments({
                  setLoading,
                  setDocuments,
                  patientId: patient.id,
                })
              }
            })
          }}
        >
          <Icon
            name='delete'
            pointer
          />
        </ButtonDelete>
      </>
    )
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={stylesModal}
      title={`Documentos de: ${patient.names} ${patient?.dad_name}`}
      closeInClickOut
    >
      {
        loading ? <Loading>Cargando...</Loading> : (
          <Container>
            <Input
              type="search"
              placeholder="Buscar por nombre de documento"
              label="Buscar"
              onChange={(value) => setSearch(value)}
              value={search}
            />
            <ListDocuments>
              {
                (
                  !documents.length ||
                  documents?.filter(
                    (document) => document.document_name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                  ).length === 0
                ) && <p>No hay documentos registrados</p>
              }

              {
                documents?.filter(
                  (document) => document.document_name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                ).map((document) => (
                  <ItemDocument key={document.id}>
                    <p>{document.document_name} (V{document.version})</p>
                    <DetailDocument>
                      {
                        document.document_type === 'template-docs'
                          ? returnWithoutSignature(document)
                          : document.date_signature ? returnSignature(document) : returnNoSignature(document)
                      }
                    </DetailDocument>
                  </ItemDocument>
                ))
              }
            </ListDocuments>

          </Container>
        )
      }
    </Modal>
  )
}

export default PatientDocuments