import * as colors from 'constants/colors'
import styled from "styled-components"

export const Container = styled.div`
  text-align: justify;
`
export const InputContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  @media(min-width: 700px){
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
`

export const FormContainer = styled.form`
  margin: 0;
  display: grid;
  gap: 15px;
`

export const Subtitle = styled.h3`
  padding: 8px 5px;
  margin: 0;
  font-weight: 700;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
  color: ${colors.neutral01};
`

export const Loading = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  min-height: 180px;
`

export const stylesModal: React.CSSProperties = {
  // height: '100%',
  width: '100%',
  maxWidth: '600px',
  // maxHeight: '500px',
}