import Button from "components/Button"
import Modal from "components/Modal"
import { ButtonsContainer, stylesButtonClose, stylesButtonSave } from "./styled"

const Question = ({
  open,
  onClose,
  description,
  onConfirm,
  loading,
}: {
  open: boolean,
  onClose: () => void,
  description: string,
  onConfirm: () => void,
  loading?: boolean,
}) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Alerta'
      closeInClickOut={false}>
      <p>{description}</p>
      <ButtonsContainer>
        <Button
          style={stylesButtonClose}
          value="No"
          onClick={onClose} />
        <Button
          style={stylesButtonSave}
          value="Si"
          loading={loading}
          onClick={onConfirm}
        />
      </ButtonsContainer>
    </Modal>
  )
}

export default Question