import { API } from "constants/envs"

export const getValidateDocumentPatient = async (
  code: string
) => {
  let url = `${API}/document/patient/get-uuid/?uuid=${code}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
  });

  const info = response.json();
  return info;
};

export const postSignatureDocumentPatient = async (
  uuid: string
) => {
  const url = `${API}/document/signature`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
    body: JSON.stringify({
      uuid
    })
  });

  const info = response.json();
  return info;
};