const icons: any = {
  // BUTTON ICONS
  add: (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
    </svg>
  ),
  edit: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575q.837 0 1.412.575l1.4 1.4q.575.575.6 1.388q.025.812-.55 1.387ZM4 21q-.425 0-.712-.288Q3 20.425 3 20v-2.825q0-.2.075-.387q.075-.188.225-.338l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225q-.188.075-.388.075ZM14.325 9.675l-.7-.7l1.4 1.4Z"/>
    </svg>
  ),
  delete: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6q-.425 0-.713-.287Q4 5.425 4 5t.287-.713Q4.575 4 5 4h4q0-.425.288-.713Q9.575 3 10 3h4q.425 0 .713.287Q15 3.575 15 4h4q.425 0 .712.287Q20 4.575 20 5t-.288.713Q19.425 6 19 6v13q0 .825-.587 1.413Q17.825 21 17 21ZM7 6v13h10V6Zm2 10q0 .425.288.712Q9.575 17 10 17t.713-.288Q11 16.425 11 16V9q0-.425-.287-.713Q10.425 8 10 8t-.712.287Q9 8.575 9 9Zm4 0q0 .425.288.712q.287.288.712.288t.713-.288Q15 16.425 15 16V9q0-.425-.287-.713Q14.425 8 14 8t-.712.287Q13 8.575 13 9ZM7 6v13V6Z"/>
    </svg>
  ),
  note: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M8 14q-.425 0-.713-.288T7 13q0-.425.288-.713T8 12h8q.425 0 .713.288T17 13q0 .425-.288.713T16 14H8Zm0 4q-.425 0-.713-.288T7 17q0-.425.288-.713T8 16h5q.425 0 .713.288T14 17q0 .425-.288.713T13 18H8Zm-3 4q-.825 0-1.413-.588T3 20V6q0-.825.588-1.413T5 4h1V3q0-.425.288-.713T7 2q.425 0 .713.288T8 3v1h8V3q0-.425.288-.713T17 2q.425 0 .713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.588 1.413T19 22H5Zm0-2h14V10H5v10ZM5 8h14V6H5v2Zm0 0V6v2Z"/>
    </svg>
  ),
  profile: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path d="M234.38 210a123.36 123.36 0 0 0-60.78-53.23a76 76 0 1 0-91.2 0A123.36 123.36 0 0 0 21.62 210a12 12 0 1 0 20.77 12c18.12-31.32 50.12-50 85.61-50s67.49 18.69 85.61 50a12 12 0 0 0 20.77-12ZM76 96a52 52 0 1 1 52 52a52.06 52.06 0 0 1-52-52Z"/>
    </svg>
  ),
  close: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z"/>
    </svg>
  ),
  // ARROWS
  'arrow-up': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor" d="M6.7 14.7q-.275-.275-.275-.7t.275-.7l4.6-4.6q.15-.15.325-.212T12 8.425q.2 0 .388.075t.312.2l4.6 4.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275Z" />
    </svg>
  ),
  'arrow-down': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z" />
    </svg>
  ),
  // PASSWORD INPUT
  'hide-eye': (
    <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.93595 12.1064 9.87247 12.101 9.80977L9.73163 12.1791C9.79433 12.1846 9.85761 12.1875 9.92187 12.1875ZM17.1625 3.2336L16.3281 2.40001C16.2988 2.37073 16.2591 2.35428 16.2177 2.35428C16.1762 2.35428 16.1365 2.37073 16.1072 2.40001L13.9721 4.53575C12.7942 3.9338 11.4702 3.63282 9.99999 3.63282C6.24608 3.63282 3.4453 5.5879 1.59765 9.49805C1.52338 9.65531 1.48486 9.82707 1.48486 10.001C1.48486 10.1749 1.52338 10.3467 1.59765 10.5039C2.33593 12.059 3.22493 13.3054 4.26464 14.2432L2.19843 16.3086C2.16915 16.3379 2.1527 16.3776 2.1527 16.4191C2.1527 16.4605 2.16915 16.5002 2.19843 16.5295L3.03222 17.3633C3.06152 17.3926 3.10124 17.409 3.14267 17.409C3.18409 17.409 3.22382 17.3926 3.25312 17.3633L17.1625 3.45469C17.177 3.44018 17.1885 3.42295 17.1964 3.40398C17.2043 3.38501 17.2083 3.36468 17.2083 3.34415C17.2083 3.32361 17.2043 3.30328 17.1964 3.28431C17.1885 3.26534 17.177 3.24811 17.1625 3.2336ZM6.48437 10C6.48431 9.40627 6.63805 8.82264 6.93059 8.30597C7.22314 7.78931 7.64452 7.35723 8.15369 7.05182C8.66286 6.74642 9.24245 6.5781 9.836 6.56327C10.4296 6.54844 11.0168 6.6876 11.5406 6.9672L10.591 7.9168C10.208 7.79418 9.79869 7.77941 9.40788 7.87412C9.01707 7.96883 8.6599 8.16936 8.37556 8.4537C8.09122 8.73804 7.89069 9.09521 7.79598 9.48602C7.70127 9.87683 7.71604 10.2862 7.83866 10.6691L6.88905 11.6188C6.62257 11.1209 6.48354 10.5647 6.48437 10Z' />
      <path d='M18.4024 9.49609C17.7149 8.04818 16.8966 6.86751 15.9475 5.9541L13.1322 8.76953C13.3695 9.38987 13.4222 10.0657 13.2837 10.7152C13.1452 11.3648 12.8216 11.9604 12.352 12.4301C11.8823 12.8997 11.2867 13.2233 10.6371 13.3618C9.98755 13.5003 9.31176 13.4477 8.69142 13.2104L6.30353 15.5982C7.41134 16.1109 8.6435 16.3672 10 16.3672C13.7539 16.3672 16.5547 14.4121 18.4024 10.502C18.4766 10.3447 18.5152 10.1729 18.5152 9.99902C18.5152 9.82511 18.4766 9.65335 18.4024 9.49609Z' />
    </svg>
  ),
  'show-eye': (
    <svg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.73437 10C7.73437 10.5802 7.96483 11.1366 8.37507 11.5468C8.78531 11.957 9.34171 12.1875 9.92187 12.1875C10.502 12.1875 11.0584 11.957 11.4687 11.5468C11.8789 11.1366 12.1094 10.5802 12.1094 10C12.1094 9.41984 11.8789 8.86344 11.4687 8.4532C11.0584 8.04297 10.502 7.8125 9.92187 7.8125C9.34171 7.8125 8.78531 8.04297 8.37507 8.4532C7.96483 8.86344 7.73437 9.41984 7.73437 10ZM18.4023 9.49609C16.5508 5.5957 13.7519 3.63281 9.99999 3.63281C6.24608 3.63281 3.44921 5.5957 1.59765 9.49805C1.52338 9.65531 1.48486 9.82706 1.48486 10.001C1.48486 10.1749 1.52338 10.3466 1.59765 10.5039C3.44921 14.4043 6.24804 16.3672 9.99999 16.3672C13.7539 16.3672 16.5508 14.4043 18.4023 10.502C18.5527 10.1855 18.5527 9.81836 18.4023 9.49609ZM9.92187 13.4375C8.02343 13.4375 6.48437 11.8984 6.48437 10C6.48437 8.10156 8.02343 6.5625 9.92187 6.5625C11.8203 6.5625 13.3594 8.10156 13.3594 10C13.3594 11.8984 11.8203 13.4375 9.92187 13.4375Z' />
    </svg>
  ),
  //FILE
  'file-pdf': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023c.479 0 .774-.242.774-.651c0-.366-.254-.586-.704-.586zm3.487.012c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018c.817.006 1.349-.444 1.349-1.396c.006-.83-.479-1.268-1.255-1.268z"/>
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.498 16.19c-.309.29-.765.42-1.296.42a2.23 2.23 0 0 1-.308-.018v1.426H7v-3.936A7.558 7.558 0 0 1 8.219 14c.557 0 .953.106 1.22.319c.254.202.426.533.426.923c-.001.392-.131.723-.367.948zm3.807 1.355c-.42.349-1.059.515-1.84.515c-.468 0-.799-.03-1.024-.06v-3.917A7.947 7.947 0 0 1 11.66 14c.757 0 1.249.136 1.633.426c.415.308.675.799.675 1.504c0 .763-.279 1.29-.663 1.615zM17 14.77h-1.532v.911H16.9v.734h-1.432v1.604h-.906V14.03H17v.74zM14 9h-1V4l5 5h-4z"/>
    </svg>
  ),
  document: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path stroke='none' d="m9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
    </svg>
  ),
  // ICONS
  detail: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z" />
      <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z" />
    </svg>
  ),
  excel: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z" />
    </svg>
  ),
}

export default icons