import { Button, Icon, Input, Layout, Question } from "components"
import { LAYOUT_TITLE_DOCS } from "data/information"
import { useDebounce } from "hooks"
import { IDocument } from "interfaces"
import { useEffect, useState } from "react"
import { Add, Details, Legend } from "./childrens"
import { deleteDocument, listDocuments } from "./services"
import { ButtonDelete, ButtonEdit, ButtonLegend, PaginationContainer, SearchContainer, Table, TableContainer } from "./styled"
import { TYPE_DOCUMENTS } from "data/typeDocuments"

const Documents = () => {
  const [list, setList] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [openAdd, setOpenAdd] = useState(false)
  const [modalQuestion, setModalQuestion] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalLegend, setModalLegend] = useState(false)
  const [openDetails, setOpenDetails] = useState<IDocument | null>(null)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, 1000)

  useEffect(() => {
    listDocuments({
      search,
      page,
      setLoading,
      setList,
      setTotal,
      setTotalPages,
    })
  }, [searchDebounce, page])

  const handleDelete = (name: string) => {
    deleteDocument({
      name: name,
      setLoading: setLoadingDelete,
      callbackList: () => listDocuments({
        search,
        page,
        setLoading,
        setList,
        setTotal,
        setTotalPages,
      })
    })
    setModalQuestion(!modalQuestion)
  }

  const handleModalDelete = (document: IDocument) => {
    setModalQuestion(!modalQuestion)
    setOpenDetails(document)
  }

  const handleModalEdit = (document: IDocument) => {
    setModalEdit(!modalEdit)
    setOpenDetails(document)
  }

  return (
    <>
      <Layout title={LAYOUT_TITLE_DOCS}>
        <SearchContainer>
          <Input
            type="search"
            placeholder="Buscar por nombre"
            label="Buscar"
            value={search}
            onChange={setSearch}
            className="filter-input search-input"
          />

          <Button
            value="Agregar"
            onClick={() => setOpenAdd(true)}
          />

          <ButtonLegend
            onClick={() => setModalLegend(!modalLegend)}>
            <Icon
              name='detail'
              pointer
            />
          </ButtonLegend>

        </SearchContainer>

        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Nombre del documento</th>
                <th>Tipo</th>
                <th>Fecha de creación</th>
                <th>Vista previa</th>
                <th align="center">Detalle</th>
              </tr>
            </thead>

            <tbody>
              {
                loading ? (
                  <tr>
                    <td colSpan={10}>cargando...</td>
                  </tr>
                ) : (
                  <>
                    {
                      list?.length === 0 && (
                        <tr>
                          <td colSpan={10}>No hay documentos registrados</td>
                        </tr>
                      )
                    }
                    {
                      list?.map((document: any, index: number) => (
                        <tr key={index}>
                          <td>{document.name}</td>
                          <td>{TYPE_DOCUMENTS.find(type => type.value === document.type)?.label}</td>
                          <td>{new Date(document.created_at).toLocaleString()}</td>
                          <td>
                            <a href={document.path} target="_blank">
                              Link
                            </a>
                          </td>
                          <td align="center">
                            <ButtonEdit
                              onClick={() => handleModalEdit(document)}>
                              <Icon
                                name='edit'
                                pointer
                              />
                            </ButtonEdit>
                            <ButtonDelete
                              onClick={() => handleModalDelete(document)} >
                              <Icon
                                name='delete'
                                pointer
                              />
                            </ButtonDelete>
                          </td>
                        </tr>
                      ))
                    }
                  </>
                )
              }
            </tbody>
          </Table>

          {
            !loading && list.length > 0 && (
              <PaginationContainer>
                <p style={{ marginRight: 'auto' }}>
                  Total: {total}
                </p>

                <Button
                  onClick={() => page > 1 && setPage(page - 1)}
                  value="Anterior"
                />
                <span>{page} / {totalPages}</span>
                <Button
                  onClick={() => page < totalPages && setPage(page + 1)}
                  value="Siguiente"
                />
              </PaginationContainer>
            )
          }
        </TableContainer>
      </Layout >

      <Add
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        callbackList={() => {
          listDocuments({
            search,
            page,
            setLoading,
            setList,
            setTotal,
            setTotalPages,
          })
        }}
      />

      <Details
        open={modalEdit}
        document={openDetails}
        onClose={() => setModalEdit(false)}
        callbackList={() => {
          listDocuments({
            search,
            page,
            setLoading,
            setList,
            setTotal,
            setTotalPages,
          })
        }}
      />

      <Legend
        open={modalLegend}
        onClose={() => setModalLegend(!modalLegend)}
      />

      <Question
        open={modalQuestion}
        onClose={() => setModalQuestion(!modalQuestion)}
        description={`¿Estás seguro de eliminar el documento ${openDetails?.name || ''}?`}
        onConfirm={() => handleDelete(openDetails?.uuid || '')}
        loading={loadingDelete}
      />
    </>
  )
}

export default Documents