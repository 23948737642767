import { Toast } from "components";
import * as links from 'constants/links';
import { UseFormSetValue } from "react-hook-form";
import { postGenerateLink } from "./api";
import { IRegisterLink } from "./props";

export const handleGenerateLink = async ({
  typeDocument,
  numberDocument,
  setLoadingRegisterLink,
  setValue,
  isYounger,
}: {
  typeDocument: string,
  numberDocument: string,
  setLoadingRegisterLink: Function,
  setValue: UseFormSetValue<IRegisterLink>,
  isYounger: boolean,
}) => {
  try {
    setLoadingRegisterLink(true)
    const response = await postGenerateLink(typeDocument, numberDocument, isYounger)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setValue('link', `${links?.generateLinkPatient}${response?.uuid}` || '')

  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoadingRegisterLink(false)
  }
}