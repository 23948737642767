import { Toast } from "components";
import { IUser } from "interfaces";
import * as userSlice from 'redux/slices/user';
import { AppDispatch } from "redux/store";
import { postAuth } from "./api";

export const handleAuth = async ({
  user,
  setLoading,
  dispatch
}: {
  user: IUser,
  setLoading: Function,
  dispatch: AppDispatch,
}) => {
  try {
    setLoading(true)
    const response = await postAuth(user)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    localStorage.setItem('token', response?.token || '')
    dispatch(userSlice.setLogged(true))

  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })

  } finally {
    setLoading(false)

  }

}