import * as colors from 'constants/colors';
import styled from 'styled-components';
import IInput from './props';

export const ComponentInput = styled.input<IInput>`
  background-color: transparent;
  border: 1px solid ${({ error }) => (error ? colors.error : '#000')};
  border-radius: 5px;
  color: ${(props) => props.color};
  height: 45px;
  padding: 10px;
  width: 100%;
  &[name="password"]{
    padding-right: 40px;
  }
  &::placeholder { 
    color: ${({ theme }) => theme.inputPlaceholderColor};
  }
  &:focus {
    border-color: ${({ theme, error }) => error ? theme.inputBorderColorError : theme.inputBorderColorFocus};
    & + label {
      color: ${({ theme, error }) => error ? theme.inputLabelColorError : theme.inputLabelColorFocus};
    }
  }
`

export const Container = styled.div`
  position: relative;
  padding: 10px 0 0 0;
`

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateY(-50%);
  padding: 0 4px;
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  color: ${({ theme }) => theme.inputLabelColor};
`

export const Error = styled.span`
  padding: 5px 10px 0;
  font-size: 13px;
  color: ${colors.error};
  line-height: 1;
  width: 100%;
  display: inline-block;
`

export const styleIconPassword: React.CSSProperties = {
  position: 'absolute',
  right: '10px',
  transform: 'translateY(50%)',
  cursor: 'pointer',
}