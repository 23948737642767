import styled from "styled-components";

export const Info = styled.div`
  display: flex;
  font-size: 14px;
  background-color: #e1edff;
  color: #6083b7;
  border-radius: 8px;
  padding: 10px;
  
  strong{
    display: contents;
  }
`

export const FormContainer = styled.form`
  display: grid;
  gap: 15px;
`