import { API } from "constants/envs"

export const getDocuments = async () => {
  let url = `${API}/document?all=1`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const postDocument = async ({
  patientId,
  documentId,
  html,
}: {
  patientId: number,
  documentId: number,
  html?: string,
}) => {
  let url = `${API}/document/patient`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body: JSON.stringify({
      patientId,
      documentId,
      html,
    }),
  });

  const info = response.json();
  return info;
}
