import * as userSlice from 'redux/slices/user';
import { AppDispatch } from "redux/store";
import { postAuthValidate } from "./api";

export const authValidate = async ({
  setLoading,
  dispatch
}: {
  setLoading: Function,
  dispatch: AppDispatch,
}) => {
  try {
    setLoading(true)
    const response = await postAuthValidate()

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    dispatch(userSlice.setLogged(true))

  } catch (error: any) {
    dispatch(userSlice.setLogged(false))

    console.error('Error de inicio de sesión:', error)
    console.log(error.message)

  } finally {
    setLoading(false)
  }

}