import { Toast } from 'components'
import { getValidateDocumentPatient, postSignatureDocumentPatient } from './api'

export const handleValidateDocumentPatient = async ({
  code,
  setLoading
}: {
  code: string,
  setLoading: Function
}) => {
  try {
    setLoading(true)
    const response = await getValidateDocumentPatient(code)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    return response

  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })

  } finally {
    // setLoading(false)
  }
}

export const handleSignature = async ({
  uuid,
  setLoading,
  setInfo
}: {
  uuid: string,
  setLoading: Function,
  setInfo: Function
}) => {
  try {
    setLoading(true)

    const response = await postSignatureDocumentPatient(uuid)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setInfo(response?.data)
  } catch (error: any) {
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  } finally {
    setLoading(false)
  }
}