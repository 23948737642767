import { createSlice } from '@reduxjs/toolkit'
import IUserState from '../types/user-state.interface'

const initialState: IUserState = {
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogged: (state, action) => {
      state.isLoggedIn = action.payload
    },
  }
})

export const {
  setLogged,
} = userSlice.actions

export default userSlice.reducer
