import * as colors from 'constants/colors';
import styled from "styled-components";

export const Container = styled.div`
  max-width: 1170px;
  padding: 30px 30px 50px 30px;
  box-shadow: 0px 0 1px rgba(0,0,0,.18), 0px 0 1px rgba(0,0,0,.18);
  min-width: 240px;
  margin: 0 auto;
  display: grid;
  gap: 15px;
  text-align: justify;
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
  color: ${colors.primary01};
`

export const Text = styled.p<{ isResalt?: boolean }>`
  ${({ isResalt }) => isResalt ? 'color: #efc72d; font-size: 18px' : ''}
`

export const Subtitle = styled.h3`
  padding: 8px 5px;
  margin: 20px 0 10px 0;
  font-weight: 700;
  font-size: 17px;
  border-bottom: 1px solid #ccc;
  color: ${colors.neutral01};
`

export const InputContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  @media(min-width: 700px){
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
`

export const FormContainer = styled.form`
  margin: 20px 0;
  display: grid;
  gap: 15px;
`

export const ButtonsContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;

  button{
    width: 200px;
  }

  .avatars{
    width: 150px;
  }

  @media(min-width: 768px){
    flex-direction: row;

    .avatars{
      width: 200px;
    }
  }
`

export const TermsContainer = styled.section`
  display: flex;
  align-items: baseline;
  gap: 10px;
  text-align: justify;
  margin-top: 20px;
`

export const Error = styled.span`
  padding: 0;
  font-size: 13px;
  color: ${colors.error};
  line-height: 1;
  width: 100%;
  display: inline-block;
`