import { API } from "constants/envs"

export const getDocuments = async (search: string, page: number) => {
  let url = `${API}/document?page=${page}${search ? `&search=${search}` : ''}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const deleteDeleteDocument = async (
  uuid: string
) => {
  let url = `${API}/document`;

  const body = JSON.stringify({ uuid });

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body,
  });

  const info = response.json();
  return info;
}