import ReactDOM from 'react-dom'
import ToastContainer from './childrens/ToastContainer'
import { IToast } from './props'
import './styles.css'

let ctToastCount = 0

const toast = (options: IToast) => {
	let rootContainer = document.getElementById('ct-container')

	if (!rootContainer) {
		rootContainer = document.createElement('div')
		rootContainer.id = 'ct-container'
		document.getElementById('root') ? document.getElementById('root')?.appendChild(rootContainer) : document.body.appendChild(rootContainer)
	}

	ctToastCount++

	const toast = {
		id: ctToastCount,
		message: options.message,
		type: options?.type || 'info',
		position: options?.position || 'top-center',
		hideAfter: options?.hideAfter || 3,
		heading: options?.heading || '',
	}

	ReactDOM.render(<ToastContainer toast={toast} />, rootContainer)
}


export default toast