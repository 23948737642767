import * as colors from 'constants/colors'
import styled from 'styled-components'
import IButton from './props'

export const ComponentButton = styled.button<IButton>`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
  padding: 10px;
  cursor: ${({ disabled, loading }) => (disabled ? loading ? 'wait' : 'not-allowed' : 'pointer')};
  background-color: ${({ outline, disabled }) => (disabled ? '#646464' : outline ? 'transparent' : colors.primary01)};
  border: ${({ disabled }) => disabled ? '0' : '1px'} solid ${colors.primary01};
  border-radius: ${({ round }) => round ? '50%' : '4px'};
  color: ${colors.neutral02};
  font-weight: 500;
  width: ${({ round }) => round ? `48px` : '100%'};
  height: 40px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  &:hover{
    background-color: #ed7e4f;
    color: ${colors.neutral02};
    fill: ${colors.neutral02};
  }
`
