import { Button, ConvertToDropdownArray, Dropdown, HtmlEditor, Modal, Toast, editorConfig } from "components"
import * as documentLegend from 'constants/documentLegend'
import { returnDateNow, returnTimeNow, returnYears } from "constants/functions"
import { IDocument } from "interfaces"
import IPatient from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { handleCopyClipboard, upperCase } from "tools/functions"
import { IAddDocument } from "./props"
import { createDocument, loadDocuments } from "./services"
import {
  CopyContainer,
  FormContainer,
  LinkContainer,
  LoadingContainer,
  TitleLinkRegister,
  stylesModal
} from "./styled"

const AddDocument = ({
  open,
  onClose,
  patient,
}: {
  open: boolean,
  onClose: () => void,
  patient: IPatient
}) => {
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [document, setDocument] = useState<IDocument | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddDocument>({
    mode: 'onChange',
  })

  const onSubmit = async (data: any) => {
    createDocument({
      patientId: data.patientId,
      documentId: data.documentId,
      html: (document?.type === 'template' || document?.type === 'template-docs') ? data.html : null,
      setLoadingCreate,
      setValue,
    })
  }

  useEffect(() => {
    if (!open) return

    loadDocuments({ setLoading, setDocuments })
    reset({
      patientId: patient.id,
      html: '',
    })
  }, [open])

  useEffect(() => {
    if (!watch('documentId')) return

    const document = documents.find(({ id }) => id === watch('documentId'))
    if (!document) return

    setDocument(document)
    returnDocumentPath(document)

  }, [watch('documentId')])

  const returnDocumentPath = async (document: IDocument) => {
    try {
      if (!document?.path) return onClose()
      const response = await fetch(document.path, { cache: 'no-cache' })
      let html = await response.text()

      if (!html) throw new Error()

      html = html.replaceAll(documentLegend.names, patient.names)
      html = html.replaceAll(documentLegend.dad_name, patient.dad_name)
      html = html.replaceAll(documentLegend.mom_name, patient.mom_name)
      html = html.replaceAll(documentLegend.document_type, upperCase(patient.doc_type))
      html = html.replaceAll(documentLegend.document_number, patient.doc_number)
      html = html.replaceAll(documentLegend.age, returnYears(patient.date_of_birth).toString())
      html = html.replaceAll(documentLegend.phone, patient.phone)
      html = html.replaceAll(documentLegend.email, patient.email)

      html = html.replaceAll(documentLegend.phone_emergency, patient?.phone_emergency || '')
      html = html.replaceAll(documentLegend.relationship_emergency, patient?.relationship_emergency || '')

      html = html.replaceAll(documentLegend.name_tutor, patient?.name_tutor || '')
      html = html.replaceAll(documentLegend.document_type_tutor, upperCase(patient?.doc_type_tutor))
      html = html.replaceAll(documentLegend.document_number_tutor, patient?.doc_number_tutor || '')
      html = html.replaceAll(documentLegend.age_tutor, returnYears(patient.date_of_birth_tutor).toString())
      html = html.replaceAll(documentLegend.email_tutor, patient?.phone_tutor || '')
      html = html.replaceAll(documentLegend.phone_tutor, patient?.email_tutor || '')

      html = html.replaceAll(documentLegend.dateNow, returnDateNow())
      html = html.replaceAll(documentLegend.timeNow, returnTimeNow())

      setValue('html', html)
    } catch {
      Toast({
        type: 'error',
        message: 'No se pudo obtener el contenido del documento',
      })
    }
  }

  const handleCopy = () => {
    const url = `${window.location.origin}${watch('link')}`
    handleCopyClipboard(url)
  }

  return (
    <Modal
      style={stylesModal}
      onClose={onClose}
      open={open}
      title='Generar documento de paciente'
      closeInClickOut
    >
      {loading && <LoadingContainer>Cargando...</LoadingContainer>}

      {!loading && <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='documentId'
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Dropdown
              isSearch
              options={ConvertToDropdownArray(documents, 'id', 'name')}
              placeholder='Documento'
              label='Documento'
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.documentId && 'Seleccione un documento'}
            />
          )}
        />

        {(document?.type === 'template' || document?.type === 'template-docs') ? (
          <>
            <Controller
              control={control}
              name='html'
              render={({ field: { value, onChange, onBlur } }) => (
                <HtmlEditor
                  modules={editorConfig.modules}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </>
        ) : (
          <Controller
            control={control}
            name='html'
            render={({ field: { value, onChange, onBlur } }) => (
              <HtmlEditor
                modules={editorConfig.modulesClean}
                value={value}
                onChange={onChange}
                readOnly
              />
            )}
          />
        )}

        {
          !watch('link') && <Button
            type='submit'
            disabled={watch('link') ? true : loadingCreate}
            loading={loadingCreate}
            value='Generar'
          />
        }

        {
          watch('link') && (
            <>
              <TitleLinkRegister>Para firmar el documento, debes enviarle el siguiente link:</TitleLinkRegister>
              <CopyContainer>
                <LinkContainer to={watch('link') || ''} target="_blank">{window.location.origin}{watch('link') || ''}</LinkContainer>
                <button onClick={handleCopy} type='button'>Copiar</button>
              </CopyContainer>
            </>
          )
        }

      </FormContainer>
      }
    </Modal>
  )
}

export default AddDocument