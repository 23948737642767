import { API } from "constants/envs";
import { encryptRSA } from "tools/encryption";

export const getLinks = async () => {
  const url = `${API}/patient/list-links`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
  });

  const info = response.json();
  return info;
}

export const deleteLinks = async (uuid: string) => {
  const url = `${API}/patient/delete-link`;

  const body = JSON.stringify({ payload: encryptRSA(JSON.stringify({ uuid })) });

  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body,
  });

  const info = response.json();
  return info;
}