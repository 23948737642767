import { Layout } from "components"

const Profile = () => {
  return (
    <Layout>
      <div>index</div>
    </Layout>
  )
}

export default Profile