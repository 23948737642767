import * as links from 'constants/links'
import { useAppDispatch } from 'hooks'
import logo_mga from 'images/logo_mga.png'
import { useEffect, useState } from 'react'
import { handleLogout } from './services'
import { Container, ItemNav, LinkNav, Logo, Logout, Nav, TooltipNav } from "./styled"

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const [pathname, setPathname] = useState<string>('')

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname) setPathname(pathname)
  }, [window.location.pathname]);

  return (
    <Container>
      <Logo>
        <img src={logo_mga} alt="Logo MGA" />
      </Logo>
      <Nav>
        <ItemNav>
          <LinkNav to={links.patients} className={pathname === links.patients ? 'active' : ''}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
              <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 19v-1.25c0-2.071-1.919-3.75-4.286-3.75h-3.428C7.919 14 6 15.679 6 17.75V19m9-11a3 3 0 1 1-6 0a3 3 0 0 1 6 0z" />
            </svg>
          </LinkNav>
          <TooltipNav className='tooltip'>Pacientes</TooltipNav>
        </ItemNav>
        <ItemNav>
          <LinkNav to={links.documents} className={pathname === links.documents ? 'active' : ''}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
              <path stroke='none' d="m9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
            </svg>
          </LinkNav>
          <TooltipNav className='tooltip'>Documentos</TooltipNav>
        </ItemNav>
      </Nav>
      <Logout onClick={() => handleLogout({ dispatch })}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M10 8V6a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-2" />
            <path d="M15 12H3l3-3m0 6l-3-3" /></g>
        </svg>
      </Logout>
    </Container>
  )
}

export default Sidebar