import { DOCUMENT_HTML } from "data/form"
import ReactQuill from "react-quill"

const HtmlEditor = ({
  modules,
  value,
  onChange,
  readOnly = false,
}: {
  modules: any
  value: string
  onChange: (e: string) => void
  readOnly?: boolean,
}) => {
  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      placeholder={DOCUMENT_HTML}
      value={value}
      onChange={onChange}
      readOnly={readOnly}

    />
  )
}

HtmlEditor.defaultProps = {
  readOnly: false,
}

export default HtmlEditor