import { Button, Dropdown, Input, Loading } from "components"
import { returnMaxDate } from "constants/functions"
import * as links from 'constants/links'
import { DAD_NAME, DATE_OF_BIRTH, DOC_NUMBER, DOC_NUMBER_TUTOR, DOC_TYPE, DOC_TYPE_TUTOR, ERROR, ERROR_DAD_NAME_LENGTH, ERROR_DOC_NUMBER_TUTOR_LENGTH, ERROR_DOC_TYPE_TUTOR_LENGTH, ERROR_GENDER_REQUIRED, ERROR_MAIL_PATTERN, ERROR_MOM_NAME_LENGTH, ERROR_NAMES_LENGTH, ERROR_NAME_TUTOR_LENGTH, ERROR_PHONE_EMERGENCY_LENGTH, ERROR_PHONE_LENGTH, ERROR_RELATIONSHIP_EMERGENCY_LENGTH, GENDER, MAIL, MOM_NAME, NAMES, NAME_TUTOR, PHONE, PHONE_EMERGENCY, REGISTER_BUTTON, RELATIONSHIP_EMERGENCY } from "data/form"
import GENDERS from "data/genders"
import { DESCRIPTION_REGISTER_PATIENT, LOADING_BUTTON, SUBTITLE_REGISTER_PATIENT, TITLE_REGISTER_PATIENT } from "data/information"
import { TYPE_DOCUMENTS_USERS } from "data/typeDocuments"
import useQuery from "hooks/useQuery"
import imageGirl from 'images/girl.jpg'
import IPatient from "interfaces/patient.interface"
import { useLayoutEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { Congratulations } from "./childrens"
import { rules as roulesGeneral, rulesYounger } from "./rules"
import { handleRegisterPatient, handleValidatePatient } from "./services"
import { ButtonsContainer, Container, FormContainer, InputContainer, Subtitle, Text, Title } from "./styled"

const RegisterPatient = () => {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const history = useHistory()
  const query = useQuery()
  const [rules, setRules] = useState(roulesGeneral)

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IPatient>({
    mode: 'onChange',
  })

  const validatePatient = async (code: string) => {
    const response = await handleValidatePatient({ code, setLoading })
    if (response?.response !== 'success') {
      history.push(links.notFound)
      return
    }

    setValue('doc_type', response?.doc_type)
    setValue('doc_number', response?.doc_number)
    setValue('is_younger', response?.is_younger)
    setValue('uuid_register', code)
    if (response?.is_younger) {
      setRules(rulesYounger)
    }
  }

  useLayoutEffect(() => {
    const code = query.get('t')
    if (!code) {
      history.push(links.notFound)
      return
    }

    validatePatient(code);
  }, [])


  const onSubmit = async (data: IPatient) => {
    handleRegisterPatient({
      data,
      setLoading,
      setStep,
    })
  }

  if (loading) return (
    <Loading />
  )

  return (
    <>
      {
        step === 1 ? (
          <Container>
            <Title>{TITLE_REGISTER_PATIENT}</Title>
            <Text dangerouslySetInnerHTML={{ __html: DESCRIPTION_REGISTER_PATIENT }} />
            <Text isResalt dangerouslySetInnerHTML={{ __html: SUBTITLE_REGISTER_PATIENT }} />

            <FormContainer
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputContainer>
                <Input
                  type="text"
                  label={DOC_TYPE}
                  value={watch('doc_type') || ''}
                  style={{ textTransform: 'uppercase' }}
                  disabled
                />
                <Input
                  type="text"
                  label={DOC_NUMBER}
                  value={watch('doc_number') || ''}
                  disabled
                />
              </InputContainer>

              <InputContainer>
                <Controller
                  control={control}
                  name='names'
                  rules={rules.names}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={NAMES}
                      label={NAMES}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.names && ERROR_NAMES_LENGTH}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='dad_name'
                  rules={rules.dad_name}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={DAD_NAME}
                      label={DAD_NAME}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.dad_name && ERROR_DAD_NAME_LENGTH}
                    />
                  )}
                />
              </InputContainer>
              <InputContainer>
                <Controller
                  control={control}
                  name='mom_name'
                  rules={rules.mom_name}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={MOM_NAME}
                      label={MOM_NAME}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.mom_name && ERROR_MOM_NAME_LENGTH}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='gender'
                  rules={rules.gender}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Dropdown
                      options={GENDERS}
                      placeholder={GENDER}
                      label={GENDER}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.gender && ERROR_GENDER_REQUIRED}
                    />
                  )}
                />
              </InputContainer>
              <InputContainer>
                <Controller
                  control={control}
                  name='date_of_birth'
                  rules={rules.date_of_birth}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="date"
                      placeholder={DATE_OF_BIRTH}
                      label={DATE_OF_BIRTH}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxDate={getValues('is_younger') ? returnMaxDate(2) : returnMaxDate(18)}
                      error={errors?.date_of_birth && ERROR}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='phone'
                  rules={rules.phone}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={PHONE}
                      label={watch('is_younger') ? `${PHONE} (OPCIONAL)` : PHONE}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.phone && ERROR_PHONE_LENGTH}
                    />
                  )}
                />
              </InputContainer>

              <Controller
                control={control}
                name='email'
                rules={rules.email}
                render={({ field: { value, onChange, onBlur } }) => (
                  <Input
                    type="text"
                    placeholder={MAIL}
                    label={watch('is_younger') ? `${MAIL} (OPCIONAL)` : MAIL}
                    value={value || ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors?.email && ERROR_MAIL_PATTERN}
                  />
                )}
              />

              {
                getValues('is_younger') ? (
                  <>
                    <Subtitle>Datos del apoderado</Subtitle>
                    <InputContainer>
                      <Controller
                        control={control}
                        name='doc_type_tutor'
                        rules={rules.doc_type_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Dropdown
                            options={TYPE_DOCUMENTS_USERS}
                            placeholder={DOC_TYPE_TUTOR}
                            label={DOC_TYPE_TUTOR}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.doc_type_tutor && ERROR_DOC_TYPE_TUTOR_LENGTH}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name='doc_number_tutor'
                        rules={rules.doc_number_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Input
                            type="text"
                            placeholder={DOC_NUMBER_TUTOR}
                            label={DOC_NUMBER_TUTOR}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.doc_number_tutor && ERROR_DOC_NUMBER_TUTOR_LENGTH}
                          />
                        )}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Controller
                        control={control}
                        name='name_tutor'
                        rules={rules.name_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Input
                            type="text"
                            placeholder={NAME_TUTOR}
                            label={NAME_TUTOR}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.name_tutor && ERROR_NAME_TUTOR_LENGTH}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name='date_of_birth_tutor'
                        rules={rules.date_of_birth_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Input
                            type="date"
                            placeholder={DATE_OF_BIRTH}
                            label={DATE_OF_BIRTH}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            maxDate={returnMaxDate(18)}
                            error={errors?.date_of_birth_tutor && ERROR}
                          />
                        )}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Controller
                        control={control}
                        name='phone_tutor'
                        rules={rules.phone_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Input
                            type="text"
                            placeholder={PHONE}
                            label={PHONE}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.phone_tutor && ERROR_PHONE_LENGTH}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name='email_tutor'
                        rules={rules.email_tutor}
                        render={({ field: { value, onChange, onBlur } }) => (
                          <Input
                            type="text"
                            placeholder={MAIL}
                            label={MAIL}
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={errors?.email_tutor && ERROR_MAIL_PATTERN}
                          />
                        )}
                      />
                    </InputContainer>
                  </>
                ) : <></>
              }

              <Subtitle>Datos de emergencia</Subtitle>
              <InputContainer>
                <Controller
                  control={control}
                  name='phone_emergency'
                  rules={rules.phone_emergency}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={PHONE_EMERGENCY}
                      label={PHONE_EMERGENCY}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.phone_emergency && ERROR_PHONE_EMERGENCY_LENGTH}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='relationship_emergency'
                  rules={rules.relationship_emergency}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Input
                      type="text"
                      placeholder={RELATIONSHIP_EMERGENCY}
                      label={RELATIONSHIP_EMERGENCY}
                      value={value || ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors?.relationship_emergency && ERROR_RELATIONSHIP_EMERGENCY_LENGTH}
                    />
                  )}
                />
              </InputContainer>


              <ButtonsContainer>
                <img
                  src={imageGirl}
                  alt="icon"
                  className="avatars"
                />

                <Button
                  disabled={loading}
                  value={loading ? LOADING_BUTTON : REGISTER_BUTTON}
                  type='submit'
                />
              </ButtonsContainer>
            </FormContainer>
          </Container>
        )
          : <Congratulations patientName={`${getValues('names') || ''} ${getValues('dad_name') || ''} ${getValues('mom_name') || ''}`} />
      }
    </>
  )
}

export default RegisterPatient