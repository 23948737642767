import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 75px 1fr;
`

export const Body = styled.section`
  padding: 0 30px 50px 30px;
  box-shadow: 0px 0 1px rgba(0,0,0,.18), 0px 0 1px rgba(0,0,0,.18);
  min-width: 240px;
  width: 100%;
  margin: 0 auto;
`

export const Title = styled.section`
  padding: 10px 2% 10px;
  max-width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.1);
  h1{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
    margin: 0;
  }
`