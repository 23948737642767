import { Toast } from "components";
import { getValidatePatient, postRegisterPatient } from "./api";

export const handleValidatePatient = async ({
  code,
  setLoading
}: {
  code: string,
  setLoading: Function
}) => {
  try {
    setLoading(true)
    const response = await getValidatePatient(code)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    return response

  } catch (error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })

  } finally {
    setLoading(false)
  }
} 

export const handleRegisterPatient = async ({
  data,
  setLoading,
  setStep
}: {
  data: any,
  setLoading: Function
  setStep: Function
}) => {
  try{
    setLoading(true)

    const response = await postRegisterPatient(data)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setStep(2)
  }catch(error: any) {
    console.error('Error de inicio de sesión:', error)
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  }finally{
    setLoading(false)
  }
}