import { API } from "constants/envs"
import { IDocument } from "interfaces"

export const postCreateDocument = async (
  data: IDocument
) => {
  let url = `${API}/document`;

  const body = JSON.stringify(data);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
      "Authorization": `Bearer ${localStorage.getItem('token') || ''}`
    },
    body,
  });

  const info = response.json();
  return info;
}