import { RSA_PUBLIC_KEY } from 'constants/envs'
const NodeRSA = require('node-rsa')

export function encryptRSA(text: string) {
  try {
    const publickKey = new NodeRSA(RSA_PUBLIC_KEY)
    const encryptedText = publickKey.encrypt(text,'base64')
    return encryptedText
  } catch (error) {
    console.log('error', error)
  }
}