import { Button, Input } from 'components'
import { ENV } from 'constants/envs'
import { ERROR_PASSWORD, ERROR_USERNAME, PASSWORD, USERNAME } from 'data/form'
import { LOADING_BUTTON, LOGIN_BUTTON } from 'data/information'
import { useAppDispatch } from 'hooks'
import logo_mga from 'images/logo_mga.png'
import { IUser } from 'interfaces'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { rules } from './rules'
import { handleAuth } from './services'
import { Container, FormContainer, Logo } from './styled'

const Login = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUser>({
    mode: 'onChange',
  })

  const onSubmit = async (user: IUser) => handleAuth({ user, setLoading, dispatch })

  useEffect(() => {
    ENV === 'development' &&
      reset({
        username: 'maythe2023',
        password: 'Mga987804573',
      })
  }, [])

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Logo src={logo_mga} alt='logo' height={80} />
        {/* <Title>{LOGIN_TITLE}</Title> */}
        <Controller
          control={control}
          name='username'
          rules={rules.username}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              type='text'
              placeholder={USERNAME}
              label={USERNAME}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.username && ERROR_USERNAME}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={rules.password}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              type='password'
              placeholder={PASSWORD}
              label={PASSWORD}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.password && ERROR_PASSWORD}
            />
          )}
        />
        <Button
          disabled={loading}
          type='submit'
          value={loading ? LOADING_BUTTON : LOGIN_BUTTON}
        />
      </FormContainer>
    </Container>
  )
}

export default Login