import styled from "styled-components"

export const stylesModal: React.CSSProperties = {
}

export const List = styled.ul`
  display: grid;
  gap: 8px;
  padding: 0;
  margin: 0;
`

export const ItemList = styled.p`
  margin: 0;

  span{
    font-size: 13px;
  }
`