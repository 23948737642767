import React from 'react'
import icons from './list'
import IProps from './props'

const Icon = ({
  className,
  name,
  onClick,
  pointer,
  style,
  height,
  width,
  fill,
  stroke,
}: IProps) => {

  const svgComponent = (name: string) => {
    const svg = icons[name]
    if(!svg) return null
    return (props: any) => React.cloneElement(svg, props)
  }

  const Component = svgComponent(name)
  if(!Component) return null

  return (
    <Component
      className={className}
      onClick={onClick}
      height={height}
      width={width}
      fill={fill}
      stroke={stroke}
      style={{
        cursor: pointer ? 'pointer' : 'default',
        ...style,
      }}
    />
  )
}

Icon.defaultProps = {
  className: '',
  pointer: false,
  height: 24,
  width: 24,
}

export default Icon