import { Button, Dropdown, Input, Modal } from 'components'
import { ERROR } from 'data/form'
import { TYPE_DOCUMENTS_USERS } from 'data/typeDocuments'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { handleCopyClipboard } from 'tools/functions'
import { IRegisterLink } from './props'
import { rules } from './rules'
import { handleGenerateLink } from './services'
import { Checkbox, CopyContainer, FormContainer, Info, LinkContainer, TitleLinkRegister } from './styled'

const LinkRegister = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  const [loadingRegisterLink, setLoadingRegisterLink] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IRegisterLink>({
    mode: 'onChange',
  })

  useEffect(() => {
    if (!open) return

    reset({
      typeDocument: 'dni',
      numberDocument: '',
      isYounger: false,
      link: '',
    })
  }, [open])

  const handleCopy = () => {
    const url = `${window.location.origin}${watch('link')}`
    handleCopyClipboard(url)
  }

  const onSubmit = async ({
    typeDocument,
    numberDocument,
    isYounger,
  }: IRegisterLink) => handleGenerateLink({ typeDocument, numberDocument, setLoadingRegisterLink, setValue, isYounger })

  return (
    <Modal
      onClose={onClose}
      open={open}
      title='Generar link de registro'
      closeInClickOut>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>

        <Controller
          control={control}
          name='typeDocument'
          rules={rules.typeDocument}
          render={({ field: { value, onChange, onBlur } }) => (
            <Dropdown
              options={TYPE_DOCUMENTS_USERS}
              placeholder={'Tipo de documento'}
              label={'Tipo de documento'}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              error={errors?.typeDocument && ERROR}
            />
          )}
        />

        <Controller
          control={control}
          name='numberDocument'
          rules={rules.numberDocument}
          render={({ field: { value, onChange, onBlur } }) => (
            <Input
              type="text"
              placeholder="Ingrese el número del documento del paciente"
              label="Número de documento"
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />

        <Controller
          control={control}
          name='isYounger'
          rules={rules.isYounger}
          render={({ field: { value, onChange, onBlur } }) => (
            <Checkbox>
              <input
                id="isYounger"
                type="checkbox"
                checked={value || false}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label htmlFor="isYounger">
                ¿Es menor de edad o dependiente?
              </label>
            </Checkbox>
          )}
        />

        <Info>
          <strong>Nota: </strong> Si es menor de edad o dependiente, se pedirá datos extras del apoderado o padres.
        </Info>

        <Button
          value='Generar'
          loading={loadingRegisterLink}
          disabled={watch('numberDocument')?.length < 8}
          type="submit"
        />
        {
          watch('link') && (
            <>
              <TitleLinkRegister>Para registrar a un paciente, debes enviarle el siguiente link:</TitleLinkRegister>
              <CopyContainer>
                <LinkContainer to={watch('link') || ''} target="_blank">{window.location.origin}{watch('link') || ''}</LinkContainer>
                <button onClick={handleCopy} type='button'>Copiar</button>
              </CopyContainer>
            </>
          )
        }
      </FormContainer>
    </Modal>
  )
}

export default LinkRegister