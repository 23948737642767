import { IOption } from "components/Dropdown/props"

const GENDERS: IOption[] = [
  {
    label: 'MASCULINO',
    value: 'M',
  },
  {
    label: 'FEMENINO',
    value: 'F',
  },
  {
    label: 'OTRO',
    value: 'O',
  }
]

export default GENDERS