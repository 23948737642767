import { Toast } from "components"
import { IListLinks } from "./props"
import { deleteLinks, getLinks } from "./api"

export const listLinks = async({
  setLoading,
  setLinks,
}: IListLinks) => {
  try{
    setLoading(true)

    const response = await getLinks()

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    setLinks(response?.patients || [])

  }catch(error: any){
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  }finally{
    setLoading(false)
  }
}

export const deleteLink = async(uuid:string, docType: string, docNumber: string) => {
  try{
    const response = await deleteLinks(uuid)

    if (response?.response !== 'success') {
      throw new Error(response?.response || 'Error al enviar los datos, intente nuevamente')
    }

    Toast({
      type: 'success',
      message: `Link del documento (${docType}) ${docNumber} fue eliminado correctamente`
    })
  }catch(error: any){
    Toast({
      type: 'error',
      message: (
        error.message === 'Load failed' ||
        error.message === 'NetworkError when attempting to fetch resource.'
      ) ? 'Intente nuevamente' : error.message
    })
  }
}