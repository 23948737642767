import { Button, Loading, Toast } from 'components'
import * as documentLegend from 'constants/documentLegend'
import { returnDateNow, returnTimeNow, returnYears } from "constants/functions"
import * as links from 'constants/links'
import { useToggle } from 'hooks'
import useQuery from 'hooks/useQuery'
import logo from 'images/logo_mga.png'
import IPatient from 'interfaces/patient.interface'
import { useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { upperCase } from 'tools/functions'
import { handleSignature, handleValidateDocumentPatient } from './services'
import { BodyHTML, CheckboxContainer, Container, HeaderSignature, TextSignature, Title } from './styled'

const RegisterSignature = () => {
  const [loading, setLoading] = useState(false)
  const [isSignature, toggleSignature] = useToggle(false)
  const [info, setInfo] = useState<{
    patient: IPatient,
    document?: any,
    documentPatient: any,
  }>({
    patient: {} as IPatient,
    documentPatient: {},
  })
  const [html, setHtml] = useState('')
  const history = useHistory()
  const query = useQuery()

  const showPageReady = !!info.documentPatient?.date_signature || info.documentPatient?.doc_type === 'template-docs'

  const validatePatient = async (code: string) => {
    const response = await handleValidateDocumentPatient({ code, setLoading })
    if (response?.response !== 'success') {
      history.push(links.notFound)
      return
    }

    setInfo(response.info)
    let path: string = ''
    if (response.info.documentPatient?.doc_type === 'template') {
      path = response.info.documentPatient?.path
    }
  
    if (response.info.documentPatient?.doc_type === 'template-docs') {
      path = response.info.documentPatient?.path
    }

    if (response.info.documentPatient?.doc_type === 'unique') {
      path = response.info?.document?.path
    }

    returnDocumentPath(path, response.info)
  }

  const returnDocumentPath = async (path: string, info: any) => {
    try {
      if (!path) return ''
      const response = await fetch(path, { cache: 'no-cache' })
      let html_ = await response.text()

      if (!html_) throw new Error()

      if (info.documentPatient?.doc_type === 'unique') {
        html_ = html_.replaceAll(documentLegend.names, info.patient.names)
        html_ = html_.replaceAll(documentLegend.dad_name, info.patient.dad_name)
        html_ = html_.replaceAll(documentLegend.mom_name, info.patient.mom_name)
        html_ = html_.replaceAll(documentLegend.document_type, upperCase(info.patient.doc_type))
        html_ = html_.replaceAll(documentLegend.document_number, info.patient.doc_number)
        html_ = html_.replaceAll(documentLegend.age, returnYears(info.patient.date_of_birth).toString())
        html_ = html_.replaceAll(documentLegend.phone, info.patient.phone)
        html_ = html_.replaceAll(documentLegend.email, info.patient.email)

        html_ = html_.replaceAll(documentLegend.phone_emergency, info.patient?.phone_emergency || '')
        html_ = html_.replaceAll(documentLegend.relationship_emergency, info.patient?.relationship_emergency || '')

        html_ = html_.replaceAll(documentLegend.name_tutor, info.patient?.name_tutor || '')
        html_ = html_.replaceAll(documentLegend.document_type_tutor, upperCase(info.patient?.doc_type_tutor))
        html_ = html_.replaceAll(documentLegend.document_number_tutor, info.patient?.doc_number_tutor || '')
        html_ = html_.replaceAll(documentLegend.age_tutor, returnYears(info.patient.date_of_birth_tutor).toString())
        html_ = html_.replaceAll(documentLegend.email_tutor, info.patient?.phone_tutor || '')
        html_ = html_.replaceAll(documentLegend.phone_tutor, info.patient?.email_tutor || '')

        if (info.documentPatient?.date_signature) {
          html_ = html_.replaceAll(documentLegend.dateNow, returnDateNow(info.documentPatient?.date_signature))
          html_ = html_.replaceAll(documentLegend.timeNow, returnTimeNow(info.documentPatient?.date_signature))
        } else {
          html_ = html_.replaceAll(documentLegend.dateNow, returnDateNow())
          html_ = html_.replaceAll(documentLegend.timeNow, returnTimeNow())
        }
      }

      setHtml(html_)

    } catch {
      Toast({
        type: 'error',
        message: 'No se pudo obtener el contenido del documento',
      })

    } finally {
      setLoading(false)

      if (info.documentPatient?.date_signature || info.documentPatient?.doc_type === 'template-docs') {
        window.print()
      }
    }

  }

  useLayoutEffect(() => {
    const code = query.get('t')
    if (!code) {
      history.push(links.notFound)
      return
    }

    validatePatient(code);
  }, [])

  return (
    <>
      {
        loading && <Loading />
      }
      {
        !loading && (
          <Container isSignature={showPageReady}>

            {showPageReady
              ? null
              : <Title>
                  Términos y condiciones de {info.patient?.names} {info.patient?.dad_name} {info.patient?.mom_name}
                </Title>
            }

            <BodyHTML isSignature={showPageReady}>
              {showPageReady &&
                <HeaderSignature>
                  <img src={logo} alt="logo" />
                  <div>
                    <span>CONSULTORIO PSICOLOGICO MGA S.A.C</span>
                    <span>20610175822</span>
                    <span>Calle Ignacio de la Puente 335-Miraflores</span>
                  </div>
                </HeaderSignature>
              }
              <div className='ql-editor' dangerouslySetInnerHTML={{ __html: html }} />
            </BodyHTML>

            {
              (info.documentPatient?.doc_type === 'template' || info.documentPatient?.doc_type === 'unique')
                ? (info.documentPatient?.date_signature)
                    ? <TextSignature>
                        Firmado exitosamente el {returnDateNow(info.documentPatient?.date_signature)} a las {returnTimeNow(info.documentPatient?.date_signature)}
                      </TextSignature>
                    : <>
                      <CheckboxContainer htmlFor="signature">
                        <input id='signature' type="checkbox" onChange={toggleSignature} checked={isSignature} />
                        He leído y estoy de acuerdo con los términos y condiciones de la web.
                      </CheckboxContainer>

                      <Button
                        disabled={loading || !isSignature}
                        onClick={() => handleSignature({
                          setLoading,
                          uuid: info.documentPatient?.uuid,
                          setInfo,
                        })}
                        value={loading ? 'Firmando...' : 'Firmar'}
                      />
                    </>
                : null
            }
          </Container >
        )
      }
    </>
  )
}

export default RegisterSignature;