import { Modal } from "components"
import * as documentLegend from 'constants/documentLegend'
import { ItemList, List, stylesModal } from "./styled"

const Legend = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) => {
  return (
    <Modal
      style={stylesModal}
      onClose={onClose}
      open={open}
      title='Leyenda de variables de documento'
      closeInClickOut
    >
      <List>
        <ItemList>
          <span>{documentLegend.names}: </span>
          Nombre del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.dad_name}: </span>
          Apellido paterno del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.mom_name}: </span>
          Apellido materno del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.document_type}: </span>
          Tipo de documento del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.document_number}: </span>
          Número de documento del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.age}: </span>
          Edad del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.phone}: </span>
          Teléfono del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.email}: </span>
          Correo electrónico del paciente
        </ItemList>

        <ItemList>
          <span>{documentLegend.phone_emergency}: </span>
          Teléfono de emergencia del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.relationship_emergency}: </span>
          Parentesco de emergencia del paciente
        </ItemList>

        <ItemList>
          <span>{documentLegend.name_tutor}: </span>
          Nombre del tutor
        </ItemList>
        <ItemList>
          <span>{documentLegend.document_type_tutor}: </span>
          Tipo de documento del tutor
        </ItemList>
        <ItemList>
          <span>{documentLegend.document_number_tutor}: </span>
          Número de documento del tutor
        </ItemList>
        <ItemList>
          <span>{documentLegend.age_tutor}: </span>
          Edad del tutor
        </ItemList>
        <ItemList>
          <span>{documentLegend.email_tutor}: </span>
          Correo electrónico del tutor
        </ItemList>
        <ItemList>
          <span>{documentLegend.phone_tutor}: </span>
          Teléfono del tutor
        </ItemList>

        <ItemList>
          <span> {documentLegend.dateNow}: </span>
          Fecha de firma del paciente
        </ItemList>
        <ItemList>
          <span>{documentLegend.timeNow}: </span>
          Hora de firma del paciente
        </ItemList>
      </List>
    </Modal>
  )
}

export default Legend