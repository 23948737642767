import ITextarea from './props'
import {
  ComponentTextarea,
  Container,
  Error,
  Label
} from './styles'

const Textarea = ({
  bg,
  height,
  color,
  error,
  onChange,
  onBlur,
  name,
  label,
  placeholder,
  value,
  readOnly,
}: ITextarea) => {
  return (
    <Container>
      <ComponentTextarea
        bg={bg}
        height={height}
        color={color}
        error={error}
        id={name}
        name={name}
        onChange={(e: any) => onChange?.(e.target.value)}
        onBlur={(e: any) => onBlur?.(e.target.value)}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
      />

      {label && (
        <Label>{label}</Label>
      )}

      {error && <Error>{error}</Error>}
    </Container>
  )
}

Textarea.defaultProps = {
  error: '',
  type: 'text',
  height: 100,
  readOnly: false,
}

export default Textarea
