import * as colors from 'constants/colors'
import { Link } from 'react-router-dom'
import styled from "styled-components"

export const Container = styled.div`
  position: sticky;
  height: 100vh;
  top: 0;
  width: 75px;
  z-index: 99;
  background-color: ${colors.primary03};
  transition: all .5s ease;
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  gap: 20px;
`

export const Logo = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 14px;
  img{
    width: 100%;
  }
`

export const Nav = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 6px 14px;
  margin: 0;
`

export const ItemNav = styled.li`
  width: 100%;
  position: relative;
  list-style: none;
  &:hover .tooltip{
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top:50%;
    transform: translateY(-50%);
  }
  
`

export const TooltipNav = styled.span`
  position: absolute;
  top:-20px;
  left:calc(100% + 15px);
  z-index: 3;
  background-color: ${colors.neutral02};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 14px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
`

export const LinkNav = styled(Link)`
  display: flex;
  width: 100%;
  height: 35px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #0067ac;
  stroke: ${colors.neutral02};
  fill: ${colors.neutral02};
  position: relative;
  transition: all .5s ease;
  border-radius: 5px;
  z-index: 12;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    background-color: ${colors.neutral02};
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
    z-index: -2;
  }
  &:hover::after,
  &.active::after {
    transform: scaleX(1);
  }

  &:hover,
  &.active{
    stroke: ${colors.primary03};
    fill: ${colors.primary03};
  }
  
`

export const IconNav = styled.img`
  width: 35px;
  height: 35px;
  stroke: ${colors.neutral02};
  fill: ${colors.neutral02};
`

export const Logout = styled.div`
  background-color: #0067ac;
  width: 100%;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.5s ease;
  stroke: ${colors.neutral02};
  display: grid;
  place-items: center;
  &:hover{
    background-color: #177cbf;
  }
`