
import { Button } from 'components'
import * as links from 'constants/links'
import error_img from 'images/404_error.png'
import { Container, Img } from './styled'

const NotFound = () => {
  const handleGoBack = () => {
    window.location.href = links.mga
  }

  return (
    <Container>
      <Img src={error_img} alt="Error 404" width={100} />
      <Button value='Volver al inicio' onClick={() => handleGoBack()} />
    </Container>
  )
}

export default NotFound