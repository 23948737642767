import { API } from "constants/envs";
import { IUser } from "interfaces";
import { encryptRSA } from "tools/encryption";

export const postAuth = async (data: IUser) => {
  const url = `${API}/auth/login`;

  const body = JSON.stringify({ payload: encryptRSA(JSON.stringify(data)) });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "*",
    },
    body,
  });

  const info = response.json();
  return info;
}