import { Button, Dropdown, Input, Modal, Textarea } from "components"
import { returnMaxDate } from "constants/functions"
import { DAD_NAME, DATE_OF_BIRTH, DOC_NUMBER, DOC_NUMBER_TUTOR, DOC_TYPE, DOC_TYPE_TUTOR, EDIT_BUTTON, ERROR, ERROR_DAD_NAME_LENGTH, ERROR_DOC_NUMBER_TUTOR_LENGTH, ERROR_DOC_TYPE_TUTOR_LENGTH, ERROR_GENDER_REQUIRED, ERROR_MAIL_PATTERN, ERROR_MOM_NAME_LENGTH, ERROR_NAMES_LENGTH, ERROR_NAME_TUTOR_LENGTH, ERROR_PHONE_EMERGENCY_LENGTH, ERROR_PHONE_LENGTH, ERROR_RELATIONSHIP_EMERGENCY_LENGTH, GENDER, MAIL, MOM_NAME, NAMES, NAME_TUTOR, OBSERVATIONS, PHONE, PHONE_EMERGENCY, RELATIONSHIP_EMERGENCY } from "data/form"
import GENDERS from "data/genders"
import { LOADING_BUTTON } from "data/information"
import { TYPE_DOCUMENTS_USERS } from "data/typeDocuments"
import IPatient from "interfaces/patient.interface"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { rules } from "./rules"
import { updatePatient } from "./services"
import { Container, FormContainer, InputContainer, Subtitle, stylesModal } from "./styled"

const PatientEdit = ({
  open,
  onClose,
  patient,
  callback,
}: {
  open: boolean,
  onClose: () => void,
  patient: IPatient,
  callback: Function,
}) => {
  const [loading, setLoading] = useState(false)

  const {
    watch,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IPatient>({
    mode: 'onChange',
  })

  useEffect(() => {
    if (!open) return

    reset({
      ...patient,
    })
  }, [open])

  const onSubmit = async (data: IPatient) => {
    updatePatient(data, setLoading, () => {
      onClose()
      callback()
    })
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      style={stylesModal}
      title={`Editar paciente ${patient.names} ${patient?.dad_name}`}
      closeInClickOut
    >
      <Container>
        <FormContainer
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputContainer>
            <Input
              type="text"
              label={DOC_TYPE}
              value={watch('doc_type') || ''}
              style={{ textTransform: 'uppercase' }}
              disabled
            />
            <Input
              type="text"
              label={DOC_NUMBER}
              value={watch('doc_number') || ''}
              disabled
            />
          </InputContainer>

          <InputContainer>
            <Controller
              control={control}
              name='names'
              rules={rules.names}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={NAMES}
                  label={NAMES}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.names && ERROR_NAMES_LENGTH}
                />
              )}
            />
            <Controller
              control={control}
              name='dad_name'
              rules={rules.dad_name}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={DAD_NAME}
                  label={DAD_NAME}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.dad_name && ERROR_DAD_NAME_LENGTH}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <Controller
              control={control}
              name='mom_name'
              rules={rules.mom_name}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={MOM_NAME}
                  label={MOM_NAME}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.mom_name && ERROR_MOM_NAME_LENGTH}
                />
              )}
            />

            <Controller
              control={control}
              name='gender'
              rules={rules.gender}
              render={({ field: { value, onChange, onBlur } }) => (
                <Dropdown
                  options={GENDERS}
                  placeholder={GENDER}
                  label={GENDER}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.gender && ERROR_GENDER_REQUIRED}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <Controller
              control={control}
              name='date_of_birth'
              rules={rules.date_of_birth}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="date"
                  placeholder={DATE_OF_BIRTH}
                  label={DATE_OF_BIRTH}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxDate={getValues('is_younger') ? returnMaxDate(2) : returnMaxDate(18)}
                  error={errors?.date_of_birth && ERROR}
                />
              )}
            />

            <Controller
              control={control}
              name='phone'
              rules={rules.phone}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={PHONE}
                  label={watch('is_younger') ? `${PHONE} (OPCIONAL)` : PHONE}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.phone && ERROR_PHONE_LENGTH}
                />
              )}
            />
          </InputContainer>

          <Controller
            control={control}
            name='email'
            rules={rules.email}
            render={({ field: { value, onChange, onBlur } }) => (
              <Input
                type="text"
                placeholder={MAIL}
                label={watch('is_younger') ? `${MAIL} (OPCIONAL)` : MAIL}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                error={errors?.email && ERROR_MAIL_PATTERN}
              />
            )}
          />

          <Controller
            control={control}
            name='observations'
            rules={rules.observations}
            render={({ field: { value, onChange, onBlur } }) => (
              <Textarea
                type="text"
                placeholder={OBSERVATIONS}
                label={OBSERVATIONS}
                value={value || ''}
                onChange={onChange}
                height={150}
                onBlur={onBlur}
              />
            )}
          />

          {
            getValues('is_younger') ? (
              <>
                <Subtitle>Datos del apoderado</Subtitle>
                <InputContainer>
                  <Controller
                    control={control}
                    name='doc_type_tutor'
                    rules={rules.doc_type_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Dropdown
                        options={TYPE_DOCUMENTS_USERS}
                        placeholder={DOC_TYPE_TUTOR}
                        label={DOC_TYPE_TUTOR}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors?.doc_type_tutor && ERROR_DOC_TYPE_TUTOR_LENGTH}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='doc_number_tutor'
                    rules={rules.doc_number_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="text"
                        placeholder={DOC_NUMBER_TUTOR}
                        label={DOC_NUMBER_TUTOR}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors?.doc_number_tutor && ERROR_DOC_NUMBER_TUTOR_LENGTH}
                      />
                    )}
                  />
                </InputContainer>
                <InputContainer>
                  <Controller
                    control={control}
                    name='name_tutor'
                    rules={rules.name_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="text"
                        placeholder={NAME_TUTOR}
                        label={NAME_TUTOR}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors?.name_tutor && ERROR_NAME_TUTOR_LENGTH}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='date_of_birth_tutor'
                    rules={rules.date_of_birth_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="date"
                        placeholder={DATE_OF_BIRTH}
                        label={DATE_OF_BIRTH}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        maxDate={returnMaxDate(18)}
                        error={errors?.date_of_birth_tutor && ERROR}
                      />
                    )}
                  />
                </InputContainer>
                <InputContainer>
                  <Controller
                    control={control}
                    name='phone_tutor'
                    rules={rules.phone_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="text"
                        placeholder={PHONE}
                        label={PHONE}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors?.phone_tutor && ERROR_PHONE_LENGTH}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='email_tutor'
                    rules={rules.email_tutor}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Input
                        type="text"
                        placeholder={MAIL}
                        label={MAIL}
                        value={value || ''}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={errors?.email_tutor && ERROR_MAIL_PATTERN}
                      />
                    )}
                  />
                </InputContainer>
              </>
            ) : <></>
          }

          <Subtitle>Datos de emergencia</Subtitle>
          <InputContainer>
            <Controller
              control={control}
              name='phone_emergency'
              rules={rules.phone_emergency}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={PHONE_EMERGENCY}
                  label={PHONE_EMERGENCY}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.phone_emergency && ERROR_PHONE_EMERGENCY_LENGTH}
                />
              )}
            />
            <Controller
              control={control}
              name='relationship_emergency'
              rules={rules.relationship_emergency}
              render={({ field: { value, onChange, onBlur } }) => (
                <Input
                  type="text"
                  placeholder={RELATIONSHIP_EMERGENCY}
                  label={RELATIONSHIP_EMERGENCY}
                  value={value || ''}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors?.relationship_emergency && ERROR_RELATIONSHIP_EMERGENCY_LENGTH}
                />
              )}
            />
          </InputContainer>

          <Button
            disabled={loading}
            value={loading ? LOADING_BUTTON : EDIT_BUTTON}
            type='submit'
          />
        </FormContainer>
      </Container>
    </Modal>
  )
}

export default PatientEdit